/* eslint-disable  @typescript-eslint/no-explicit-any */
import { getEnv } from 'shared/src/lib/env';
import { isInOneNav } from 'shared/src/onenav/is-in-onenav';
import { isInData } from 'shared/src/onenav/routing';

const nodeEnv = process.env.NODE_ENV;
const PROD_EU = 'prod-eu';
const PROD_US = 'production';
const STAGING = 'staging';

export enum DefaultAWSRegion {
  US = 'us-west-2',
  EU = 'eu-central-1',
}

export interface IntegrationOAuthConfig {
  clientID: string;
  redirectURI: string;
}

// biome-ignore lint/suspicious/noExplicitAny: TODO: AMP-75106 Fix this the next time the file is edited.
const getClientSentryDsn = (nodeEnv: any): string => {
  if (nodeEnv === 'test') {
    return '';
  }

  return 'https://f7003d3a8d52453a834e096cb203501c@o13027.ingest.sentry.io/78869';
};

// biome-ignore lint/suspicious/noExplicitAny: TODO: AMP-75106 Fix this the next time the file is edited.
const getAmplitudeSdkGaiaKey = (environment: any, nodeEnv: any) => {
  if (nodeEnv === 'test') {
    return '';
  }
  if (nodeEnv === 'development' || nodeEnv === 'perf') {
    // local development
    return 'ba4b96b3b6cde1d722e60aac370093c7';
  }

  switch (environment) {
    case PROD_US:
      return 'e5a2c9bdffe949f7da77e6b481e118fa';
    case 'staging':
    case 'prod-sandbox':
      return 'a3c23da3b63f5b6bed50b0b6f252567f';
    case 'development':
      return 'ce58b28cace35f7df0eb241b0cd72044';
    case PROD_EU:
      return '4ccbf62d3b2a2b840cdc385c724a68ca';
    default:
      return '';
  }
};

const getAmplitudeSdkEnvConfig = (
  // biome-ignore lint/suspicious/noExplicitAny: TODO: AMP-75106 Fix this the next time the file is edited.
  environment: any,
  // biome-ignore lint/suspicious/noExplicitAny: TODO: AMP-75106 Fix this the next time the file is edited.
  nodeEnv: any,
): { key: string; serverZone: 'US' | 'EU' } | null => {
  if (nodeEnv === 'test') {
    return null;
  }

  switch (environment) {
    // EU
    case PROD_EU:
      return {
        key: 'c4918f03c986765e0d7b495199f86e48',
        serverZone: 'EU',
      };
    default:
      return null;
  }
};

const getAmplitudeAppTeamInfrastructureKey = (
  // biome-ignore lint/suspicious/noExplicitAny: TODO: AMP-75106 Fix this the next time the file is edited.
  environment: any,
  // biome-ignore lint/suspicious/noExplicitAny: TODO: AMP-75106 Fix this the next time the file is edited.
  nodeEnv: any,
) => {
  switch (nodeEnv) {
    case 'test':
      return '';
    case 'development':
    case 'perf':
      return 'bb4c3eafb5ccb0f0e73e32dd5c63ecda';
  }

  switch (environment) {
    case PROD_US:
      return 'fb0efa3ca84d3fa90b9722e73a65c730';
    case PROD_EU:
      return '93be69dba20cd60ddd9d3a48e176ceac';
    default:
      return '';
  }
};

// biome-ignore lint/suspicious/noExplicitAny: TODO: AMP-75106 Fix this the next time the file is edited.
const getAmpliEnvironment = (environment: any, nodeEnv: any): string => {
  switch (nodeEnv) {
    case 'test':
    case 'perf':
      return 'development';
    case 'development': // refers to local development
      return 'local';
  }

  switch (environment) {
    case PROD_US:
      return 'production';
    case 'staging':
    case 'prod-sandbox':
      return 'staging';
    case 'development':
      return 'development';
    case PROD_EU:
      return 'prodeu';
    default:
      return 'development';
  }
};

// biome-ignore lint/suspicious/noExplicitAny: TODO: AMP-75106 Fix this the next time the file is edited.
const getSlackClientId = (deployment: any, environment: any, nodeEnv: any) => {
  if (nodeEnv === 'test') {
    return '';
  }

  if (environment === PROD_EU) {
    return '2359418194.2175895532818'; // prod eu slack app
  }

  switch (deployment) {
    case 'prod':
      return '2359418194.79837860256';
    case 'stag':
    case 'dev':
    default:
      return '2359418194.77355742816';
  }
};

const getFacebookAppId = () => {
  return '391178095022400';
};

const getTiktokAppId = () => {
  return 'TODO_AD_ID';
};

// biome-ignore lint/suspicious/noExplicitAny: TODO: AMP-75106 Fix this the next time the file is edited.
const getNATGateways = (environment: any) => {
  if (environment === PROD_EU) {
    return ['3.124.22.25', '18.157.59.125', '18.192.47.195'];
  }

  return ['52.33.3.219', '52.27.10.221', '35.162.216.242'];
};

// biome-ignore lint/suspicious/noExplicitAny: TODO: AMP-75106 Fix this the next time the file is edited.
const getDefaultAWSRegion = (environment: any): DefaultAWSRegion => {
  switch (environment) {
    case PROD_EU:
      return DefaultAWSRegion.EU;
    case PROD_US:
    default:
      return DefaultAWSRegion.US;
  }
};

// biome-ignore lint/suspicious/noExplicitAny: TODO: AMP-75106 Fix this the next time the file is edited.
const getVpce = (environment: any) => {
  if (environment === PROD_EU) {
    return 'vpce-0ea17096d8975a789';
  }

  return 'vpce-88b275e1';
};

// biome-ignore lint/suspicious/noExplicitAny: TODO: AMP-75106 Fix this the next time the file is edited.
const getRootPrincipal = (environment: any) => {
  // EU
  if (environment === PROD_EU) {
    return 'arn:aws:iam::202493300829:root';
  }

  return 'arn:aws:iam::358203115967:root';
};

const PROD_KEY = 'client-TOagPM8vfADC9LhZwsUjF04G6g6QKGnt';
const EU_KEY = 'client-NH902YI9HX5xgNzlUUUsxKNFYIab7NZZ';
const STAGING_KEY = 'client-75jOyETRMUlbhbwa1GcuXRwxycj7JL4Q';
const DEVELOPMENT_KEY = 'client-Vne8KdiZLCS9P6jeTKVAnitvKdV1irtV';
const LATEST_SUBDOMAINS = ['apps.prod-sandbox', 'latest'];
// biome-ignore lint/suspicious/noExplicitAny: TODO: AMP-75106 Fix this the next time the file is edited.
const getSkylabClientKey = (environment: any, nodeEnv: any) => {
  if (nodeEnv === 'test') {
    return '';
  }
  switch (environment) {
    case PROD_US:
      return PROD_KEY;
    case PROD_EU:
      return EU_KEY;
    case 'staging':
      return STAGING_KEY;
    case 'prod-sandbox': {
      const subdomain = location.hostname.split('.').slice(0, -2).join('.');
      return LATEST_SUBDOMAINS.includes(subdomain) ? PROD_KEY : STAGING_KEY;
    }
    default:
      return DEVELOPMENT_KEY;
  }
};

// biome-ignore lint/suspicious/noExplicitAny: TODO: AMP-75106 Fix this the next time the file is edited.
const getGrowthTeamSkylabClientKey = (environment: any, nodeEnv: any) => {
  if (nodeEnv === 'test') {
    return '';
  }
  switch (environment) {
    case PROD_US:
      return 'client-GyxvAihJLEL1CBkpmrgHkiMXvEo93TUZ';
    case PROD_EU:
      return 'client-56uIPC0LGe0XXinktzdHtlTe3wWBtp6t';
    case 'staging':
    case 'prod-sandbox':
      return 'client-K5yOijjSKqIs9aHedgKUncOvi1KWeqMz';
    case 'development':
    default:
      return 'client-7GtYXpQxRVXpbxOJszfwPUq9NKkwhKLK';
  }
};

// biome-ignore lint/suspicious/noExplicitAny: TODO: AMP-75106 Fix this the next time the file is edited.
const getOnenavSkylabClientKey = (environment: any, nodeEnv: any) => {
  if (nodeEnv === 'test') {
    return '';
  }
  switch (environment) {
    case PROD_US:
      return 'client-T48zq8Ph4z7MY185gwy9qf2ZWYptjJOW';
    case PROD_EU:
      return 'client-WUybuSZBR5imrpbVc92vfo4G1eub1V81';
    case 'staging':
    case 'prod-sandbox':
      return 'client-AsPMVbYmw247R5zYDlqsYb0nvxSgSXe0';
    case 'development':
    default:
      return 'client-bCxcfSA7VBs0ek8TYjF9YsOvKusZ42k3';
  }
};

// biome-ignore lint/suspicious/noExplicitAny: TODO: AMP-75106 Fix this the next time the file is edited.
const getAmplitudeOrgId = (environment: any) => {
  if (environment === PROD_EU) {
    return '100000002';
  }

  return '36958';
};

const getAgGridLicense = () => {
  return 'Using_this_{AG_Grid}_Enterprise_key_{AG-051425}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Amplitude}_is_granted_a_{Multiple_Applications}_Developer_License_for_{5}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{3_February_2025}____[v3]_[01]_MTczODU0MDgwMDAwMA==59fe4a620fc5e7c064f6c15133efd7fc';
};

// biome-ignore lint/suspicious/noExplicitAny: TODO: AMP-75106 Fix this the next time the file is edited.
const getUserAvatarOrigin = (environment: any) => {
  // NOTE(willh): User avatars are currently stored in the same bucket as all other static web
  // assets. But not all assets are split by environment e.g. the built js files are always hosted
  // from the US CloudFront (static.amplitude.com).
  if (environment === PROD_EU) {
    return 'https://static.eu.amplitude.com';
  }

  return 'https://static.amplitude.com';
};

export const getIntercomClientId = (
  environment: string | undefined,
  nodeEnv?: string | undefined,
): string => {
  if (nodeEnv === 'development') {
    return '283fce0b-158e-4f80-a06d-3e439c2b7e19';
  }

  if (environment === 'staging') {
    return '45d79374-69cc-4554-8119-a7b13974c87a';
  }

  if (environment === PROD_EU) {
    return 'aafdfbf9-60a1-40dc-bd65-aaa90ff19a7b';
  }

  return '30826bf7-cfab-4c24-ae77-b5ddd877a15e';
};

export const getHubSpotClientId = (
  environment: string | undefined,
  nodeEnv?: string | undefined,
): string => {
  if (nodeEnv === 'development') {
    return '6811cb3d-e012-495a-8295-d5f63bf87a19';
  }

  if (environment === PROD_EU) {
    return '70b91d47-9fdb-4803-a542-845c222c31dd';
  }

  return 'ebe8696e-b615-461d-897d-dbba95ad1026';
};

export const getHubSpotRedirectURI = (
  environment: string | undefined,
  nodeEnv?: string | undefined,
): string => {
  if (nodeEnv === 'development') {
    return 'http://localhost:3001/config/hubspot/auth';
  }

  if (environment === PROD_EU) {
    return 'https://analytics.eu.amplitude.com/config/hubspot/auth';
  }

  return 'https://analytics.amplitude.com/config/hubspot/auth';
};

export const getMailchimpConfig = (
  environment: string | undefined,
  nodeEnv: string | undefined,
): IntegrationOAuthConfig => {
  switch (nodeEnv) {
    case 'development':
      // local
      return {
        clientID: '631944772372',
        redirectURI: 'http://127.0.0.1:3000/config/mailchimp/auth',
      };
    case 'perf':
    case 'test':
      return {
        clientID: '',
        redirectURI: '',
      };
  }

  switch (environment) {
    case PROD_US:
      return {
        clientID: '533899365394',
        redirectURI: 'https://analytics.amplitude.com/config/mailchimp/auth',
      };
    case 'staging':
    case 'prod-sandbox':
      return {
        clientID: '998197057866',
        redirectURI:
          'https://analytics.staging.amplitude.com/config/mailchimp/auth',
      };
    case PROD_EU:
      return {
        clientID: '719164686963',
        redirectURI: 'https://analytics.eu.amplitude.com/config/mailchimp/auth',
      };
    default:
      return {
        clientID: '',
        redirectURI: '',
      };
  }
};

export const getQualtricsConfig = (
  environment: string | undefined,
  nodeEnv: string | undefined,
): IntegrationOAuthConfig => {
  switch (nodeEnv) {
    case 'development':
      // local
      return {
        clientID: 'bdf1f132e4273742437fabdcfbbb21df',
        redirectURI: 'https://127.0.0.1:3000/config/integration-oauth/callback',
      };
    case 'perf':
    case 'test':
      return {
        clientID: '',
        redirectURI: '',
      };
  }

  switch (environment) {
    case PROD_US:
      return {
        clientID: 'cc1a6b6bfb112f7dc7fbea9c6fc89ac6',
        redirectURI:
          'https://analytics.amplitude.com/config/integration-oauth/callback',
      };
    case 'staging':
    case 'prod-sandbox':
      return {
        clientID: 'c5ca1ec0fa1dc9fde273dc956d5ea656',
        redirectURI:
          'https://analytics.staging.amplitude.com/config/integration-oauth/callback',
      };
    case PROD_EU:
      return {
        clientID: '55864d8a4fbe708236989014b6611e36',
        redirectURI:
          'https://analytics.eu.amplitude.com/config/integration-oauth/callback',
      };
    default:
      return {
        clientID: '',
        redirectURI: '',
      };
  }
};

export const getTiktokAdConfig = (
  environment: string | undefined,
  nodeEnv: string | undefined,
): IntegrationOAuthConfig => {
  switch (nodeEnv) {
    case 'development':
      // local
      return {
        clientID: '7103819669118648322',
        redirectURI: 'http://127.0.0.1:3000/config/tiktokads/auth',
      };
    case 'perf':
    case 'test':
      return {
        clientID: '',
        redirectURI: '',
      };
  }

  switch (environment) {
    case PROD_US:
      return {
        clientID: '7099694989835239425',
        redirectURI: 'https://analytics.amplitude.com/config/tiktokads/auth',
      };
    case 'staging':
    case 'prod-sandbox':
      return {
        clientID: '7099695140096196610',
        redirectURI:
          'https://analytics.staging.amplitude.com/config/tiktokads/auth',
      };
    case PROD_EU:
      return {
        clientID: '7102524732683059202',
        redirectURI: 'https://analytics.eu.amplitude.com/config/tiktokads/auth',
      };
    default:
      return {
        clientID: '',
        redirectURI: '',
      };
  }
};

export const getSnapchatAdConfig = (
  environment: string | undefined,
  nodeEnv: string | undefined,
): IntegrationOAuthConfig => {
  switch (nodeEnv) {
    case 'development':
      // local
      return {
        clientID: '7361e8c3-96fb-4475-a68b-ece34ce3dfc6',
        redirectURI: 'https://127.0.0.1:3000/config/integration-oauth/callback',
      };
    case 'perf':
    case 'test':
      return {
        clientID: '',
        redirectURI: '',
      };
  }

  switch (environment) {
    case PROD_US:
      return {
        clientID: '632eb6c5-25c4-4bae-8b6f-7bafd18d4398',
        redirectURI:
          'https://analytics.amplitude.com/config/integration-oauth/callback',
      };
    case 'staging':
    case 'prod-sandbox':
      return {
        clientID: 'a7f18a09-b0f8-4eb6-92b1-65b1bb62424b',
        redirectURI:
          'https://analytics.staging.amplitude.com/config/integration-oauth/callback',
      };
    case PROD_EU:
      return {
        clientID: '7d3616d8-81a3-4d1a-9b49-1b3f1e5264f9',
        redirectURI:
          'https://analytics.eu.amplitude.com/config/integration-oauth/callback',
      };
    default:
      return {
        clientID: '',
        redirectURI: '',
      };
  }
};

export const getSalesforceV2Config = (
  environment: string | undefined,
  nodeEnv: string | undefined,
): IntegrationOAuthConfig => {
  switch (nodeEnv) {
    case 'development':
      // local
      return {
        clientID:
          '3MVG9gDRuIiLyYCp2wc.rq5glNG9fLqP2rHewiMOK13jg..0hKIKGKW.4UMcQIZbHHmrDZcL68nhi1Ww5GfAY',
        redirectURI: 'http://localhost:3001/config/integration-oauth/callback',
      };
    case 'perf':
    case 'test':
      return {
        clientID: '',
        redirectURI: '',
      };
  }

  switch (environment) {
    case PROD_US:
      return {
        clientID:
          '3MVG9fMtCkV6eLhdpgzRSQyseA3BX216XgDyZxtpyOLN9.eoBdEtshIN2tRoj4d7X8l7EOfp41Qaet1xXS9CH',
        redirectURI:
          'https://analytics.amplitude.com/config/integration-oauth/callback',
      };
    case 'staging':
    case 'prod-sandbox':
      return {
        clientID:
          '3MVG9gDRuIiLyYCp2wc.rq5glNG9fLqP2rHewiMOK13jg..0hKIKGKW.4UMcQIZbHHmrDZcL68nhi1Ww5GfAY',
        redirectURI:
          'https://analytics.staging.amplitude.com/config/integration-oauth/callback',
      };
    case PROD_EU:
      return {
        clientID:
          '3MVG9fMtCkV6eLhdpgzRSQyseA3BX216XgDyZxtpyOLN9.eoBdEtshIN2tRoj4d7X8l7EOfp41Qaet1xXS9CH',
        redirectURI:
          'https://analytics.eu.amplitude.com/config/integration-oauth/callback',
      };
    default:
      return {
        clientID: '',
        redirectURI: '',
      };
  }
};

const getHermesHost = (environment?: string, nodeEnv?: string) => {
  if (nodeEnv === 'development') {
    return 'http://localhost:3007';
  }

  switch (environment) {
    case PROD_US:
      return 'https://notify.amplitude.com';
    case PROD_EU:
      return 'https://notify.eu.amplitude.com';
    case 'staging':
    case 'prod-sandbox':
      return 'https://notify.staging.amplitude.com';
    case 'development':
    default:
      return 'https://notify.dev.amplitude.com';
  }
};

// biome-ignore lint/suspicious/noExplicitAny: TODO: AMP-75106 Fix this the next time the file is edited.
const getEventServerURL = (environment: any) => {
  if (environment === PROD_US) {
    return 'https://api.amplitude.com';
  }
  if (environment === PROD_EU) {
    return 'https://api.eu.amplitude.com';
  }

  return '';
};

// biome-ignore lint/suspicious/noExplicitAny: TODO: AMP-75106 Fix this the next time the file is edited.
const getHttpApiUrl = (environment: any, nodeEnv: any) => {
  if (environment === PROD_EU) {
    return 'https://api.eu.amplitude.com/2/httpapi';
  }
  if (environment === PROD_US || nodeEnv === PROD_US) {
    return 'https://api.amplitude.com/2/httpapi';
  }

  // A fallback, expecting this to not cause any issues, but please do change back to '' if necessary
  return 'https://api.amplitude.com/2/httpapi';
};

// biome-ignore lint/suspicious/noExplicitAny: TODO: AMP-75106 Fix this the next time the file is edited.
const getHttpApiV2Url = (environment: any, nodeEnv: any) => {
  if (environment === PROD_EU) {
    return 'https://api.eu.amplitude.com/2/httpapi';
  }
  if (environment === PROD_US || nodeEnv === PROD_US) {
    return 'https://api2.amplitude.com/2/httpapi';
  }

  // A fallback, expecting this to not cause any issues, but please do change back to '' if necessary
  return 'https://api2.amplitude.com/2/httpapi';
};

// biome-ignore lint/suspicious/noExplicitAny: TODO: AMP-75106 Fix this the next time the file is edited.
const getJsonataTemplateConfigURL = (environment: any, nodeEnv: any) => {
  switch (nodeEnv) {
    case 'development':
      // local
      return 'http://localhost:3000/config/adapter-v2/jsonata-template';
    case 'perf':
    case 'test':
      return '';
  }

  switch (environment) {
    case PROD_US:
      return 'https://analytics.amplitude.com/config/adapter-v2/jsonata-template';
    case 'staging':
    case 'prod-sandbox':
      return 'https://analytics.staging.amplitude.com/config/adapter-v2/jsonata-template';
    case PROD_EU:
      return 'https://analytics.eu.amplitude.com/config/adapter-v2/jsonata-template';
    default:
      return '';
  }
};

// biome-ignore lint/suspicious/noExplicitAny: TODO: AMP-75106 Fix this the next time the file is edited.
const getCustomerTemplateConfigURL = (environment: any, nodeEnv: any) => {
  switch (nodeEnv) {
    case 'development':
      // local
      return 'http://localhost:3000/config/adapter-v2/customer-template';
    case 'perf':
    case 'test':
      return '';
  }

  switch (environment) {
    case PROD_US:
      return 'https://analytics.amplitude.com/config/adapter-v2/customer-template';
    case 'staging':
    case 'prod-sandbox':
      return 'https://analytics.staging.amplitude.com/config/adapter-v2/customer-template';
    case PROD_EU:
      return 'https://analytics.eu.amplitude.com/config/adapter-v2/customer-template';
    default:
      return '';
  }
};

// biome-ignore lint/suspicious/noExplicitAny: TODO: AMP-75106 Fix this the next time the file is edited.
const getMailchimpWebhookURL = (environment: any, nodeEnv: any) => {
  switch (nodeEnv) {
    case 'development':
      // local
      return 'http://localhost:3000/config/mailchimp/webhook';
    case 'perf':
    case 'test':
      return '';
  }

  switch (environment) {
    case PROD_US:
      return 'https://analytics.amplitude.com/config/mailchimp/webhook';
    case 'staging':
    case 'prod-sandbox':
      return 'https://analytics.staging.amplitude.com/config/mailchimp/webhook';
    case PROD_EU:
      return 'https://analytics.eu.amplitude.com/config/mailchimp/webhook';
    default:
      return '';
  }
};

const getStargateHost = (environment?: string, nodeEnv?: string) => {
  if (nodeEnv === 'development') {
    return 'http://localhost:3005';
  }

  switch (environment) {
    case PROD_US:
      return 'https://data.amplitude.com';
    case PROD_EU:
      return 'https://data.eu.amplitude.com';
    case 'staging':
    case 'prod-sandbox':
      return 'https://data.staging.amplitude.com';
    case 'development':
    default:
      return 'https://data.dev.amplitude.com';
  }
};

const getDataConnectionsPublicKey = (
  environment?: string,
  nodeEnv?: string,
): { version: string; key: string } => {
  const devPublicKey = {
    version: '1',
    key: 'MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA7avYdn04kWmYfu+4+9DGz7YMJMUP3FlySbVKTZW/gr2ta3EgSJecQqC+0h7or18xSo1melPymeTg62g+ri8Vxpdr6BYIevRVvtij/pv9oB4XOepsNg5OS99cfatjZIrplTcoeSyVZ27dyBvxV8in1BekkCmlLPkzHp3P9Zf7Fdccy4TQGJyr2mqTrNkCPsmHADv5lizdmk5evXIVJ8rv5M0zBIfuMzokgEi8HLP1Dsc6n3FFR97MJTd5Tu/sUIsJ8QBQpJhD7wf+HzHPcdMMjQ+r/ra619ZM5LSXdwGnlJ/FqIkowyo+MucgsfJVL/DG8huTwU4jw01E6gTw2Ra8i29uHmEDS+HMzEt0rUJsV2lqEockCOLjdk3LAYCEZBYekkk01RfiJgv/5FgCwjrtXISdZ0pLIUE+/SyNZSkqUcq9uMmPd3uMRs+Bife9uylbFNGXXSh5GT7Xg23QBYgL0tg8ZL7l0l6mXNbL1s7nR7O9Jv3ZtE6mHOxmDOm0vJQ/e9GYccZGOU+YBdcrILXJRnuPVYSdNwQ7cVgUnicJp6vcgnNHSsdYLtCNwWJq9eQv279gkb5X6fRwt9npo5dGhWMN0aXUiLBMRTIHyOqUHqKSSyxrFO89HB0CVUt5vtz0JapRBDSnrB1MYH4832iiztq1ShuIDPDZG0cLvEIp8HUCAwEAAQ==',
  };
  const prodUsPublicKey = {
    version: '1',
    key: 'MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAkps25IQayeH5oexwWoukfVdJ9Ck2jP1hBaRYn5JBMk23MTlr7cZooXGm6GD0ZjJ2akFd9ZWBkkLf7cXnWEbOwgv/iSESJMga/ubWBlh9MsOJFMfRIxgMr8Rd5JUoOmr9/qAE3+vGnwTFWmVrNLV31r+WDXe1894EX+HM1RRXCL6k3s13xeGw2nFnwZVwbeUZdMlxapI5VYnJOh/mZP3nl1D6ZSjvTWkawMarOVZrhh7Wl5N5TCdrEQh+8rWg3MeZshYtuV2VaYdaWrKQLRHvlqLOIXs6bbGAXOQskDT8IvM5jmj9O4iPg5gLu0i0OKRikG8wH4wQ3aqyfUINl8rI/+WZfOxaM+hgv3AFehL+6O/ZuyXn3qIJ7p17o8YfRhavuiK8LeHy4CB9LJEzJxeAfgxXB2dZYkhKnKFAFpdzvesIz648PafWMbNUIyLLiIr910tfFIr/5EA1gTwTA5k/JdifEAje80QGNt2i/3Z7A+2/tDRPgrLcXxqk0gjFiSAnllbeZORjqf6Fe0gM5Zfc8e2fg2nB46N9t2KMSCXD9fXMcTikHzaeCfa1LQ9+ovm+t+lz8Lgw57fZJnffEnFqHbxp8fdsLq+r1ANFNg2KQUJp+G4Cb+eC84FUpPBpwbzoJCEAOU6uwE03wQtrvJY9TTwr2PwKFS35qPN1YwGaFqsCAwEAAQ==',
  };
  const prodEuPublicKey = {
    version: '1',
    key: 'MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA1sVCAW1GTnga3A0yTGZOYflj4hyStyiHovQhK/MOmvl6Hlh6RDpXEnmrcfXwiFukmAJESjzdDtartj6VpkOaEKQ/yTEBvs2FtQVAq3ry1g+j0g2DCbj86lTm+2UkVGAPGnGv0BRO+1M/irqxZ4UhRic/BwFU+OfpGDeE88SriaU9CF3DmE6IDge/6uCm9bdSHGYLmCLsDXE/lI6CQ8pQLMJy9JDNP3MbkPSaLArrCY3dn3FS6Y9YVxZxHBAvIjdzSq+DfBJ51nLwMvROj01Gziy0EMWAk0HMysyg0x4Vujrpg2N3cAp+3cegMZLisTgH77knm0DjZtYFZiTItEfnBBEL5GZrYCDcAp89/rX5EpFwZNXqPdK6vaDAASQ9NtOEh66j0TW48mVJ6vkVKlZi3txTomeh8mm9WASl6qjeX1yHc8MmOi8JRgOQMMV1NAbLckGtHN7Y7d490EULTHzvD/cAL+0uUvxWBptkEs3YG0R8XKuheCWk10sHS+vwrWaNIybRJT8Saeh2TdQXzCEnfqCwgYzQNJ6sCH9kKTRoz8GkAyIBRpRCQ2oldBMtzjcreNXFv0QSqlvR6gMkuzIp0k84pUBCk5eoqFi+RUu9liVco20VIiIymKUlrkvcP1GXPrc3VHPh3M7q8PFVwaELyD++I/ha8KzMCbT/d0wuTcsCAwEAAQ==',
  };

  if (nodeEnv === 'development') {
    return devPublicKey;
  }
  switch (environment) {
    case PROD_US:
      return prodUsPublicKey;
    case PROD_EU:
      return prodEuPublicKey;
    case 'staging':
    case 'prod-sandbox':
    case 'development':
    default:
      return devPublicKey;
  }
};

export const getUserflowClientToken = (
  environment?: string,
  nodeEnv?: string,
): string => {
  const stagingUserFlowClientToken = 'ct_xwegrbo2yjgv3dkyzpmysjve6i';

  if (nodeEnv === 'development') {
    return stagingUserFlowClientToken;
  }
  switch (environment) {
    case PROD_US:
    case PROD_EU:
      return 'ct_hwkn3nviz5gjnb53iixkwhmc2q';
    case 'staging':
    case 'prod-sandbox':
    case 'development':
    default:
      return stagingUserFlowClientToken;
  }
};

/**
 * Returns the slack app id used for the slack v2 app. This is used for
 * enabling app suggestions: https://api.slack.com/start/distributing/directory#suggestions
 */
export const getSlackAppId = (
  environment?: string,
  nodeEnv?: string,
): string => {
  if (nodeEnv === 'development') {
    // local
    return 'A02HXJELM6Y';
  }

  switch (environment) {
    case PROD_US:
      return 'A02PDFHC1NX';
    case PROD_EU:
      // We don't yet have a v2 slack app for prod eu
      return '';
    case 'staging':
    case 'prod-sandbox':
      return 'A02NYR6E9V5';
    case 'development':
    default:
      return 'A02KU7WQZD0';
  }
};

const getLightningHost = (environment?: string, nodeEnv?: string) => {
  if (isInOneNav()) {
    return getEnv('REACT_APP_ANALYTICS_HOST');
  }

  if (nodeEnv === 'development') {
    return 'http://localhost:3001';
  }

  switch (environment) {
    case PROD_US:
      return 'https://analytics.amplitude.com';
    case PROD_EU:
      return 'https://analytics.eu.amplitude.com';
    case 'staging':
      return 'https://analytics.staging.amplitude.com';
    case 'prod-sandbox':
      return 'https://analytics.prod-sandbox.amplitude.com';
    case 'development':
    default:
      return 'https://analytics.dev.amplitude.com';
  }
};

/**
 * Return base URL for admin-v2 pages.
 * @param environment
 * @param nodeEnv
 * @returns
 */
const getAdminHost = (environment?: string, nodeEnv?: string) => {
  // TODO - probably needs to handle one-nav, but admin client is not currently
  // in one nav

  if (nodeEnv === 'development') {
    return 'http://localhost:3002/admin-v2';
  }

  switch (environment) {
    case PROD_US:
      return 'https://analytics.amplitude.com/admin-v2';
    case PROD_EU:
      return 'https://analytics.eu.amplitude.com/admin-v2';
    case 'staging':
      return 'https://analytics.staging.amplitude.com/admin-v2';
    case 'prod-sandbox':
      return 'https://analytics.prod-sandbox.amplitude.com/admin-v2';
    case 'development':
    default:
      return 'https://analytics.dev.amplitude.com/admin-v2';
  }
};

/**
 * Returns the URL for the Orbit API.  Note that the result will already contain the '/o' path
 * when required.  Do not add it yourself.
 */
const getOrbitApiBaseUrl = (environment?: string, nodeEnv?: string): string => {
  if (nodeEnv === 'development') {
    // When in onenav, use the onenav proxy (via HTTPS) which will communicate with local servers via
    // HTTP.
    if (isInOneNav()) {
      return `${getEnv('REACT_APP_ORBIT_HOST')}/o`;
    }

    // When not in one nav and on local, connect directly to the running server via HTTP.
    return 'http://localhost:4000';
  }

  // When not in local environment, do not include the /o path for onenav as we connect directly to
  // the orbit server.
  if (isInOneNav()) {
    return `${getEnv('REACT_APP_ORBIT_HOST')}`;
  }

  if (isInData) {
    return process.env.REACT_APP_ORBIT_HOST || '';
  }
  // TODO: remove below hardcoding of urls and use process.env once all environments are supported
  // for all products.
  switch (environment) {
    case PROD_US:
      return 'https://data-api.amplitude.com';
    case PROD_EU:
      return 'https://data-api.eu.amplitude.com';
    case 'prod-sandbox':
      return 'https://data-api.prod-sandbox.amplitude.com';
    case 'staging':
      return 'https://data-api.staging.amplitude.com';
    case 'local':
    default:
      return 'http://localhost:4000';
  }
};

/**
 * Returns the URL for the Thunder API.  Note that the result will already contain the '/t' path
 * when required.  Do not add it yourself.
 */
const getThunderApiBaseUrl = (nodeEnv?: string) => {
  // When in onenav, use the onenav proxy (via HTTPS) which will communicate with local servers via
  // HTTP.
  if (isInOneNav()) {
    return `${getEnv('REACT_APP_THUNDER_HOST')}/t`;
  }

  // When not in one nav and on local, connect directly to the running server via HTTP.
  if (nodeEnv === 'development') {
    return 'http://localhost:3030';
  }

  // Otherwise, make use of frontend-lb routing to hit Thunder when on deployed environment
  // Note: window.location.origin is used here instead of hardcoding the URL based on environment
  // in order to support our ephemeral environments since process.env.REACT_APP_THUNDER_HOST is not
  // yet supported for all environments.
  // TODO: use process.env once all environments are supported for all products.
  return `${window.location.origin}/t`;
};

const getDashApiBaseUrl = (nodeEnv?: string) => {
  // When in onenav, use the onenav proxy (via HTTPS) which will communicate with local servers via
  // HTTP.
  if (isInOneNav()) {
    return getEnv('REACT_APP_DASH_HOST');
  }

  // When not in one nav and on local, connect directly to the running server via HTTP.
  if (nodeEnv === 'development') {
    return 'http://localhost:3000';
  }

  // Otherwise, make use of frontend-lb routing to hit Dash when on deployed environment (only for
  // routes specified in the frontend-lb config):
  // amplitude/deploy/blob/master/k8s/prod/services/frontend-lb/configmap.yaml#L291
  // Note: window.location.origin is used here instead of hardcoding the URL based on environment
  // in order to support our ephemeral environments since process.env.REACT_APP_THUNDER_HOST is not
  // yet supported for all environments.
  // TODO: use process.env once all environments are supported for all products.
  return `${window.location.origin}`;
};

export const getOneNavHost = (
  environment?: string,
  nodeEnv?: string,
): string => {
  // todo: move this to the deploy repo
  if (nodeEnv === 'development') {
    return 'https://localhost:3010';
  }

  switch (environment) {
    case PROD_US:
      return 'https://app.amplitude.com';
    case PROD_EU:
      return 'https://app.eu.amplitude.com';
    case 'staging':
      return 'https://apps.stag2.amplitude.com';
    case 'prod-sandbox':
      return 'https://app.prod-sandbox.amplitude.com';
    case 'development':
    default:
      return 'https://app.dev.amplitude.com';
  }
};

const getCerebroHost = (environment?: string, nodeEnv?: string) => {
  if (nodeEnv === 'development') {
    return 'http://localhost:3004';
  }

  switch (environment) {
    case PROD_US:
      return 'https://app.amplitude.com';
    case PROD_EU:
      return 'https://app.eu.amplitude.com';
    case 'staging':
      return 'https://apps.stag2.amplitude.com';
    case 'prod-sandbox':
      return 'https://app.prod-sandbox.amplitude.com';
    case 'development':
    default:
      if (process.env.CI !== 'true') {
        console.warn('Unknown cerebro host');
      }

      return '';
  }
};

// This should only be used for Cypress tests, not application logic
const getStripePriceIdMap = (environment?: string, nodeEnv?: string) => {
  const testingPriceIds = {
    PLANS: {
      // Plus V1 Plan Price IDs (legacy)
      PLUS_PLAN_5M: 'price_0L7np17vaQQgJjsxiNbqke46',
      PLUS_PLAN_10M: 'price_0L7npk7vaQQgJjsxx1lWBRNV',
      // Plus V2 Plan Price IDs (legacy)
      PLUS_PLAN_MTU_25K_YEARLY: 'price_0M207q7vaQQgJjsxniUqQIvx',
      PLUS_PLAN_MTU_50K_YEARLY: 'price_0M209B7vaQQgJjsxGJIQxSCz',
      PLUS_PLAN_MTU_100K_YEARLY: 'price_0M20BG7vaQQgJjsx6l0F46P6',
      PLUS_PLAN_MTU_25K_MONTHLY: 'price_0M4Gd47vaQQgJjsx5ZG1ZjAw',
      PLUS_PLAN_MTU_50K_MONTHLY: 'price_0M4VtH7vaQQgJjsxJQmRGCAU',
      PLUS_PLAN_MTU_100K_MONTHLY: 'price_0M4Vtd7vaQQgJjsx29UlK9Tf',
      // Plus V3 Plan Price IDs
      PLUS_V3_PLAN_MTU_1K_YEARLY: 'price_0NXU6v7vaQQgJjsxHo3Ke22s',
      'PLUS_V3_PLAN_MTU_2.5K_YEARLY': 'price_0PgYq07vaQQgJjsxfeHWdggC',
      PLUS_V3_PLAN_MTU_5K_YEARLY: 'price_0NXU9k7vaQQgJjsxNxjX3dwG',
      PLUS_V3_PLAN_MTU_10K_YEARLY: 'price_0NXUBR7vaQQgJjsxvIIML5wL',
      PLUS_V3_PLAN_MTU_25K_YEARLY: 'price_0NXUCE7vaQQgJjsx5p5r0wm7',
      PLUS_V3_PLAN_MTU_50K_YEARLY: 'price_0NXUE77vaQQgJjsxswPT1hVA',
      PLUS_V3_PLAN_MTU_75K_YEARLY: 'price_0PgabF7vaQQgJjsxBo4V74rt',
      PLUS_V3_PLAN_MTU_100K_YEARLY: 'price_0NXUGr7vaQQgJjsxpCrOoNj5',
      PLUS_V3_PLAN_MTU_150K_YEARLY: 'price_0PgabH7vaQQgJjsxpSPQAl5w',
      PLUS_V3_PLAN_MTU_200K_YEARLY: 'price_0NXUHk7vaQQgJjsxPC8GXuzq',
      PLUS_V3_PLAN_MTU_300K_YEARLY: 'price_0NXUIR7vaQQgJjsxCc4OEbcU',
      PLUS_V3_PLAN_MTU_1K_MONTHLY: 'price_0NXU6v7vaQQgJjsxGBcSBnB6',
      'PLUS_V3_PLAN_MTU_2.5K_MONTHLY': 'price_0PgYpz7vaQQgJjsxr9a1seld',
      PLUS_V3_PLAN_MTU_5K_MONTHLY: 'price_0NXU9k7vaQQgJjsx9dZpki2F',
      PLUS_V3_PLAN_MTU_10K_MONTHLY: 'price_0NXUBR7vaQQgJjsxIeBsbW6N',
      PLUS_V3_PLAN_MTU_25K_MONTHLY: 'price_0NXUCE7vaQQgJjsxKfZeTtUh',
      PLUS_V3_PLAN_MTU_50K_MONTHLY: 'price_0NXUE77vaQQgJjsxhL4PqEAv',
      PLUS_V3_PLAN_MTU_75K_MONTHLY: 'price_0PgabE7vaQQgJjsx1zRBDrtj',
      PLUS_V3_PLAN_MTU_100K_MONTHLY: 'price_0NXUGr7vaQQgJjsxXYu01xdL',
      PLUS_V3_PLAN_MTU_150K_MONTHLY: 'price_0PgabH7vaQQgJjsxmKIdUilh',
      PLUS_V3_PLAN_MTU_200K_MONTHLY: 'price_0NXUHk7vaQQgJjsxatmCKmwp',
      PLUS_V3_PLAN_MTU_300K_MONTHLY: 'price_0NXUIR7vaQQgJjsxaDu1Pz90',
      // Plus V3 EV Plan Intital Testing Price IDs
      PLUS_V3_PLAN_EV_20M_YEARLY: 'price_0P5u267vaQQgJjsx4dSKIOhk',
      PLUS_V3_PLAN_EV_20M_MONTHLY: 'price_0P5tzh7vaQQgJjsxfEcJL51x',
      PLUS_V3_PLAN_EV_10M_YEARLY: 'price_0Oqiau7vaQQgJjsxBHjnNUkX',
      PLUS_V3_PLAN_EV_10M_MONTHLY: 'price_0OqiaF7vaQQgJjsxOvyyGy6R',
      PLUS_V3_PLAN_EV_5M_YEARLY: 'price_0Ow9rL7vaQQgJjsxBvuQwqkb',
      PLUS_V3_PLAN_EV_5M_MONTHLY: 'price_0Ow9qn7vaQQgJjsxLn41JxEZ',
    },
    ADDONS: {
      // Accounts for Plus V1 Price IDs (legacy)
      ACCOUNTS_5M_YEARLY: 'price_0MgETg7vaQQgJjsxpbjuwWfW',
      ACCOUNTS_10M_YEARLY: 'price_0MgEUi7vaQQgJjsxvlQ8uCrt',
      // Accounts for Plus V2 Price IDs (legacy)
      ACCOUNTS_25K_YEARLY: 'price_0MYZN07vaQQgJjsxU6moYsfW',
      ACCOUNTS_50K_YEARLY: 'price_0MYZLr7vaQQgJjsxiGTeo9yq',
      ACCOUNTS_100K_YEARLY: 'price_0MWTUB7vaQQgJjsxoIGMTTQ3',
      ACCOUNTS_25K_MONTHLY: 'price_0MYZNf7vaQQgJjsxfY8KsIfJ',
      ACCOUNTS_50K_MONTHLY: 'price_0MYZMO7vaQQgJjsxgmyKUNYu',
      ACCOUNTS_100K_MONTHLY: 'price_0MYZL87vaQQgJjsx7Gnf2vYm',
      // Accounts for Plus V3 Price IDs
      ACCOUNTS_PLUS_V3_1K_YEARLY: 'price_0NXUQ77vaQQgJjsx3WvWi703',
      'ACCOUNTS_PLUS_V3_2.5K_YEARLY': 'price_0PgYq17vaQQgJjsx0zd0alL5',
      ACCOUNTS_PLUS_V3_5K_YEARLY: 'price_0NXUQQ7vaQQgJjsxd4SwKn1N',
      ACCOUNTS_PLUS_V3_10K_YEARLY: 'price_0NXUR37vaQQgJjsxLSz9o0fs',
      ACCOUNTS_PLUS_V3_25K_YEARLY: 'price_0NXURN7vaQQgJjsx5GL3Mhpf',
      ACCOUNTS_PLUS_V3_50K_YEARLY: 'price_0NXURz7vaQQgJjsxHbxpfcMs',
      ACCOUNTS_PLUS_V3_75K_YEARLY: 'price_0PgabG7vaQQgJjsxzsh3SpYK',
      ACCOUNTS_PLUS_V3_100K_YEARLY: 'price_0NXUSc7vaQQgJjsxZFDXZw57',
      ACCOUNTS_PLUS_V3_150K_YEARLY: 'price_0PgabI7vaQQgJjsxWh0NmajD',
      ACCOUNTS_PLUS_V3_200K_YEARLY: 'price_0NXUTD7vaQQgJjsxUwZ65B8T',
      ACCOUNTS_PLUS_V3_300K_YEARLY: 'price_0NXUTc7vaQQgJjsxHCZYg4JV',
      ACCOUNTS_PLUS_V3_1K_MONTHLY: 'price_0NXUPg7vaQQgJjsxUf4IKC7T',
      'ACCOUNTS_PLUS_V3_2.5K_MONTHLY': 'price_0PgYq17vaQQgJjsxJkgM4DMH',
      ACCOUNTS_PLUS_V3_5K_MONTHLY: 'price_0NXUPg7vaQQgJjsxcPA8cKHc',
      ACCOUNTS_PLUS_V3_10K_MONTHLY: 'price_0NXUPg7vaQQgJjsx4IoTpmQD',
      ACCOUNTS_PLUS_V3_25K_MONTHLY: 'price_0NXUPg7vaQQgJjsxBBUXTeaQ',
      ACCOUNTS_PLUS_V3_50K_MONTHLY: 'price_0NXUPg7vaQQgJjsx6CdeWfro',
      ACCOUNTS_PLUS_V3_75K_MONTHLY: 'price_0PgabH7vaQQgJjsxmKIdUilh',
      ACCOUNTS_PLUS_V3_100K_MONTHLY: 'price_0NXUPg7vaQQgJjsxUGIkAWUw',
      ACCOUNTS_PLUS_V3_150K_MONTHLY: 'price_0PgabI7vaQQgJjsx71tqV3aQ',
      ACCOUNTS_PLUS_V3_200K_MONTHLY: 'price_0NXUPg7vaQQgJjsxEfow8uVo',
      ACCOUNTS_PLUS_V3_300K_MONTHLY: 'price_0NXUPg7vaQQgJjsxr15jyaVJ',
      // Plus V3 EV Plan Intital Testing Price IDs
      ACCOUNTS_PLUS_V3_20M_YEARLY: 'price_0P5u9D7vaQQgJjsxOgUSfHgc',
      ACCOUNTS_PLUS_V3_10M_YEARLY: 'price_0OwA6n7vaQQgJjsx3TZnPBbz',
      ACCOUNTS_PLUS_V3_5M_YEARLY: 'price_0OwA897vaQQgJjsxJJgwQiNA',
      ACCOUNTS_PLUS_V3_20M_MONTHLY: 'price_0P5u7W7vaQQgJjsxZGj8lwlW',
      ACCOUNTS_PLUS_V3_10M_MONTHLY: 'price_0OwA6Q7vaQQgJjsxz784TtUS',
      ACCOUNTS_PLUS_V3_5M_MONTHLY: 'price_0OwA7O7vaQQgJjsx0pPmE23f',
    },
  };

  if (nodeEnv === 'development') {
    return testingPriceIds;
  }

  const productionPriceIds = {
    PLANS: {
      // Plus V1 Plan Price IDs (legacy)
      PLUS_PLAN_5M: 'price_0L61qB7vaQQgJjsxvIXNYjE5',
      PLUS_PLAN_10M: 'price_0L624j7vaQQgJjsxG1McEbXK',
      // Plus V2 Plan Price IDs (legacy)
      PLUS_PLAN_MTU_25K_YEARLY: 'price_0M205X7vaQQgJjsx6UO9NHgr',
      PLUS_PLAN_MTU_50K_YEARLY: 'price_0M209z7vaQQgJjsxC1C6u1lw',
      PLUS_PLAN_MTU_100K_YEARLY: 'price_0M20CS7vaQQgJjsxixjcWUPM',
      PLUS_PLAN_MTU_25K_MONTHLY: 'price_0M4Vuz7vaQQgJjsxZl6aFSaA',
      PLUS_PLAN_MTU_50K_MONTHLY: 'price_0M4VvN7vaQQgJjsxkRjkO3Or',
      PLUS_PLAN_MTU_100K_MONTHLY: 'price_0M4Vvr7vaQQgJjsxbIt2uppY',
      // Plus V3 Plan Price IDs
      PLUS_V3_PLAN_MTU_1K_YEARLY: 'price_0NXWoi7vaQQgJjsxlr6EZEcw',
      'PLUS_V3_PLAN_MTU_2.5K_YEARLY': 'price_0PgXgo7vaQQgJjsx8srg2Gdf',
      PLUS_V3_PLAN_MTU_5K_YEARLY: 'price_0NXWoe7vaQQgJjsxiBb8vrrk',
      PLUS_V3_PLAN_MTU_10K_YEARLY: 'price_0NXWoZ7vaQQgJjsx8jzAGhKK',
      PLUS_V3_PLAN_MTU_25K_YEARLY: 'price_0NXWoV7vaQQgJjsxpN9dCzZE',
      PLUS_V3_PLAN_MTU_50K_YEARLY: 'price_0NXWoK7vaQQgJjsxtU9pju1Y',
      PLUS_V3_PLAN_MTU_75K_YEARLY: 'price_0PgbYj7vaQQgJjsxI4nRWyOv',
      PLUS_V3_PLAN_MTU_100K_YEARLY: 'price_0NXWoG7vaQQgJjsxZGnCkqvr',
      PLUS_V3_PLAN_MTU_150K_YEARLY: 'price_0PgbYl7vaQQgJjsx4HnK9UL2',
      PLUS_V3_PLAN_MTU_200K_YEARLY: 'price_0NXWoD7vaQQgJjsxgkAYEwQf',
      PLUS_V3_PLAN_MTU_300K_YEARLY: 'price_0NXWo87vaQQgJjsxd2FZx5Os',
      PLUS_V3_PLAN_MTU_1K_MONTHLY: 'price_0NXWoi7vaQQgJjsxurktAN0E',
      'PLUS_V3_PLAN_MTU_2.5K_MONTHLY': 'price_0PgXgo7vaQQgJjsxHYdR6GD7',
      PLUS_V3_PLAN_MTU_5K_MONTHLY: 'price_0NXWoe7vaQQgJjsxPsCgslhE',
      PLUS_V3_PLAN_MTU_10K_MONTHLY: 'price_0NXWoZ7vaQQgJjsxX8zNQ3Z0',
      PLUS_V3_PLAN_MTU_25K_MONTHLY: 'price_0NXWoV7vaQQgJjsxXl9O251j',
      PLUS_V3_PLAN_MTU_50K_MONTHLY: 'price_0NXWoK7vaQQgJjsxLwIHBpuK',
      PLUS_V3_PLAN_MTU_75K_MONTHLY: 'price_0PgbYi7vaQQgJjsxs7TUcFOz',
      PLUS_V3_PLAN_MTU_100K_MONTHLY: 'price_0NXWoG7vaQQgJjsxW5LFzym9',
      PLUS_V3_PLAN_MTU_150K_MONTHLY: 'price_0PgbYk7vaQQgJjsxUJ7LH4cl',
      PLUS_V3_PLAN_MTU_200K_MONTHLY: 'price_0NXWoD7vaQQgJjsxQ267GyaD',
      PLUS_V3_PLAN_MTU_300K_MONTHLY: 'price_0NXWo87vaQQgJjsxLvsYP2NA',
      // Plus V3 EV Plan Intital Testing Price IDs
      PLUS_V3_PLAN_EV_20M_YEARLY: 'price_0P9x8V7vaQQgJjsxTn40n0pb',
      PLUS_V3_PLAN_EV_20M_MONTHLY: 'price_0P9x8V7vaQQgJjsxcmsGVRfN',
      PLUS_V3_PLAN_EV_10M_YEARLY: 'price_0Or5xY7vaQQgJjsxNrbdxCeR',
      PLUS_V3_PLAN_EV_10M_MONTHLY: 'price_0Or5xY7vaQQgJjsx1AXsuLQv',
      PLUS_V3_PLAN_EV_5M_YEARLY: 'price_0Ow9w37vaQQgJjsxOz7BX5US',
      PLUS_V3_PLAN_EV_5M_MONTHLY: 'price_0Ow9w37vaQQgJjsxzaCUANN2',
    },
    ADDONS: {
      // Accounts for Plus V1 Price IDs (legacy)
      ACCOUNTS_5M_YEARLY: 'price_0MgEbw7vaQQgJjsxEfkCryqM',
      ACCOUNTS_10M_YEARLY: 'price_0MgEcH7vaQQgJjsxUj3sCpRd',
      // Accounts for Plus V2 Price IDs (legacy)
      ACCOUNTS_25K_YEARLY: 'price_0MYZbr7vaQQgJjsxj2OBEpHu',
      ACCOUNTS_50K_YEARLY: 'price_0MYZbr7vaQQgJjsxXneEzNot',
      ACCOUNTS_100K_YEARLY: 'price_0MYZbr7vaQQgJjsxjKVRiqJi',
      ACCOUNTS_25K_MONTHLY: 'price_0MYZbr7vaQQgJjsxSWY2fCuG',
      ACCOUNTS_50K_MONTHLY: 'price_0MYZbr7vaQQgJjsxjRHhdrw8',
      ACCOUNTS_100K_MONTHLY: 'price_0MYZbr7vaQQgJjsxtap4cqQR',
      // Accounts for Plus V3 Price IDs
      ACCOUNTS_PLUS_V3_1K_YEARLY: 'price_0NXWlf7vaQQgJjsxRzJioNlh',
      'ACCOUNTS_PLUS_V3_2.5K_YEARLY': 'price_0PgXgp7vaQQgJjsxFlUfiRPg',
      ACCOUNTS_PLUS_V3_5K_YEARLY: 'price_0NXWlf7vaQQgJjsx2akAdRo8',
      ACCOUNTS_PLUS_V3_10K_YEARLY: 'price_0NXWlf7vaQQgJjsxxjpGK7pk',
      ACCOUNTS_PLUS_V3_25K_YEARLY: 'price_0NXWlf7vaQQgJjsxAECsOrpw',
      ACCOUNTS_PLUS_V3_50K_YEARLY: 'price_0NXWlf7vaQQgJjsxY9lpIzuq',
      ACCOUNTS_PLUS_V3_75K_YEARLY: 'price_0PgbYj7vaQQgJjsxE7n4x9lu',
      ACCOUNTS_PLUS_V3_100K_YEARLY: 'price_0NXWlf7vaQQgJjsxBYZu7tMv',
      ACCOUNTS_PLUS_V3_150K_YEARLY: 'price_0PgbYm7vaQQgJjsxcMUciZnS',
      ACCOUNTS_PLUS_V3_200K_YEARLY: 'price_0NXWlf7vaQQgJjsxGjJZhJ5l',
      ACCOUNTS_PLUS_V3_300K_YEARLY: 'price_0NXWle7vaQQgJjsxKaXlA8K6',
      ACCOUNTS_PLUS_V3_1K_MONTHLY: 'price_0NXWlf7vaQQgJjsxo3EbFO4G',
      'ACCOUNTS_PLUS_V3_2.5K_MONTHLY': 'price_0PgXgp7vaQQgJjsxIdttmLBp',
      ACCOUNTS_PLUS_V3_5K_MONTHLY: 'price_0NXWlf7vaQQgJjsx8bK0La25',
      ACCOUNTS_PLUS_V3_10K_MONTHLY: 'price_0NXWlf7vaQQgJjsxhhdwCWeb',
      ACCOUNTS_PLUS_V3_25K_MONTHLY: 'price_0NXWlf7vaQQgJjsxbBzl67Lm',
      ACCOUNTS_PLUS_V3_50K_MONTHLY: 'price_0NXWlf7vaQQgJjsxW3ycFQEp',
      ACCOUNTS_PLUS_V3_75K_MONTHLY: 'price_0PgbYj7vaQQgJjsxdHk0FOfl',
      ACCOUNTS_PLUS_V3_100K_MONTHLY: 'price_0NXWlf7vaQQgJjsxVCYWSyVC',
      ACCOUNTS_PLUS_V3_150K_MONTHLY: 'price_0PgbYm7vaQQgJjsxIDgbIUc7',
      ACCOUNTS_PLUS_V3_200K_MONTHLY: 'price_0NXWlf7vaQQgJjsx3eHZSPrD',
      ACCOUNTS_PLUS_V3_300K_MONTHLY: 'price_0NXWlf7vaQQgJjsxV1lDjKcc',
      // Plus V3 EV Plan Intital Testing Price IDs
      ACCOUNTS_PLUS_V3_20M_YEARLY: 'price_0P9xgS7vaQQgJjsx80LNMVdG',
      ACCOUNTS_PLUS_V3_10M_YEARLY: 'price_0OwACY7vaQQgJjsxTtImg8nc',
      ACCOUNTS_PLUS_V3_5M_YEARLY: 'price_0OwADH7vaQQgJjsxyW9pXkOR',
      ACCOUNTS_PLUS_V3_20M_MONTHLY: 'price_0P9xgh7vaQQgJjsx4bCA8YIu',
      ACCOUNTS_PLUS_V3_10M_MONTHLY: 'price_0OwAC67vaQQgJjsxD3rzVoZx',
      ACCOUNTS_PLUS_V3_5M_MONTHLY: 'price_0Oz7oA7vaQQgJjsxiBG03iaO',
    },
  };

  switch (environment) {
    case PROD_US:
    case PROD_EU:
      return productionPriceIds;
    case 'staging':
    case 'prod-sandbox':
    case 'development':
    default:
      return testingPriceIds;
  }
};

export const getStripePublishableKey = (environment?: string): string => {
  if (environment === PROD_US || environment === PROD_EU) {
    return 'pk_live_EF4qk7RfFBjZnnbweBoJPBtf';
  }

  return 'pk_test_rzpxG79p6THbLKHS4KoqFLTe';
};

export const getOneNavAmplitudeSDKKey = (
  environment?: string,
  nodeEnv?: string,
): string => {
  if (nodeEnv === 'test') {
    return '';
  }
  if (nodeEnv === 'development' || nodeEnv === 'perf') {
    // local development
    return 'e5d324086cddadbb2d14da9dd483bfd9';
  }

  switch (environment) {
    case PROD_US:
      return '844501a921b53fe9edfb105600bd14f8';
    case 'staging':
    case 'prod-sandbox':
      return '3c3ebc5b0250a69e599349b9cf7f0992';
    case 'development':
      return '';
    case PROD_EU:
      return '7209749aaf591e3e8a74aa40f0388485';
    default:
      return '';
  }
};

export const getCerebroClientSentryDsn = (
  environment: string | undefined,
  nodeEnv: string | undefined,
): string => {
  if (nodeEnv === 'test') {
    return '';
  }

  switch (environment) {
    // US
    case 'production':
      return 'https://e3993fc28c3b42e18f51d2cdb24e04f6@o13027.ingest.sentry.io/5669140';
    case 'staging':
    case 'prod-sandbox':
    case 'development':
    default:
      return 'https://e3993fc28c3b42e18f51d2cdb24e04f6@o13027.ingest.sentry.io/5669140';
  }
};

export const getSkylabClientSentryDsn = (
  environment: string | undefined,
  nodeEnv: string | undefined,
): string => {
  if (nodeEnv === 'test') {
    return '';
  }

  switch (environment) {
    case PROD_US:
    case PROD_EU:
    case STAGING:
      return 'https://af219e1dd5d04e6db907344d7dfbaa6a@o13027.ingest.sentry.io/5367982';
    default:
      return '';
  }
};

export const getWormholeSentryDsn = (
  environment: string | undefined,
  nodeEnv: string | undefined,
): string => {
  if (nodeEnv === 'test') {
    return '';
  }

  switch (environment) {
    case PROD_US:
    case PROD_EU:
    case STAGING:
      return 'https://15d1431ee9934aa91ca6061e617df635@o13027.ingest.us.sentry.io/4508580632985600';
    default:
      return '';
  }
};

export const getExperimentOverlaySentryDsn = (
  environment: string | undefined,
  nodeEnv: string | undefined,
): string => {
  if (nodeEnv === 'test') {
    return '';
  }

  switch (environment) {
    case PROD_US:
    case PROD_EU:
    case STAGING:
      return 'https://e6ee9f9b79215d9ef4315bd4649dd63d@o13027.ingest.us.sentry.io/4508133830295552';
    default:
      return '';
  }
};

export const getStargateClientSentryDsn = (nodeEnv?: string): string => {
  if (nodeEnv === 'test') {
    return '';
  }

  return 'https://f24a040c352f45af811e277a92372da6@o13027.ingest.sentry.io/5974455';
};

const getOverlayInjectScriptHost = (environment?: string, nodeEnv?: string) => {
  if (nodeEnv === 'development') {
    return 'https://local.amplitude.com:3008';
  }

  switch (environment) {
    case PROD_US:
      return 'https://experiment-overlay.prod.us-west-2.amplitude.com';
    case PROD_EU:
      return 'https://experiment-overlay.prod.eu-central-1.amplitude.com';
    case 'staging':
      return 'https://experiment-overlay.stag2.amplitude.com';
    default:
      console.warn('Unknown overlay inject host for environment variable');

      return '';
  }
};

export default {
  amplitudeOrgId: getAmplitudeOrgId(window.__ENVIRONMENT__),
  baseUrl: 'https://app.amplitude.com/analytics',
  keys: {
    AG_GRID_LICENSE_KEY: getAgGridLicense(),
    AMPLITUDE_SDK_GAIA_KEY: getAmplitudeSdkGaiaKey(
      window.__ENVIRONMENT__,
      nodeEnv,
    ),
    AMPLITUDE_APP_TEAM_INFRASTRUCTURE_KEY: getAmplitudeAppTeamInfrastructureKey(
      window.__ENVIRONMENT__,
      nodeEnv,
    ),
    CEREBRO_SENTRY_DSN: getCerebroClientSentryDsn(
      window.__ENVIRONMENT__,
      nodeEnv,
    ),
    ONENAV_AMPLITUDE_SDK_KEY: getOneNavAmplitudeSDKKey(
      window.__ENVIRONMENT__,
      nodeEnv,
    ),
    ONENAV_SKYLAB_CLIENT_KEY: getOnenavSkylabClientKey(
      window.__ENVIRONMENT__,
      nodeEnv,
    ),
    ONENAV_DATADOG_APP_ID: '357920cd-e21b-44e2-a9a5-c34edf090cd7',
    ONENAV_DATADOG_CLIENT_TOKEN: 'pubb3f5636caaa372205e519d422579cf30',
    ONENAV_SENTRY_DSN:
      'https://136dc13e72cc4ee18515253b3f8165cf@o13027.ingest.sentry.io/4505280404979712',
    FACEBOOK_APP_ID: getFacebookAppId(),
    TIKTOK_APP_ID: getTiktokAppId(),
    GOOGLE_ADS_CLIENT_ID:
      '94349717549-nqgopuknlcdrfsn9v74m8ve8818j1jrf.apps.googleusercontent.com',
    SENTRY_DSN: getClientSentryDsn(nodeEnv),
    SKYLAB_SENTRY_DSN: getSkylabClientSentryDsn(
      window.__ENVIRONMENT__,
      nodeEnv,
    ),
    SKYLAB_CLIENT_KEY: getSkylabClientKey(window.__ENVIRONMENT__, nodeEnv),
    GROWTH_TEAM_SKYLAB_CLIENT_KEY: getGrowthTeamSkylabClientKey(
      window.__ENVIRONMENT__,
      nodeEnv,
    ),
    STARGATE_SENTRY_DSN: getStargateClientSentryDsn(nodeEnv),
    WORMHOLE_SENTRY_DSN: getWormholeSentryDsn(window.__ENVIRONMENT__, nodeEnv),
    SLACK_CLIENT_ID: getSlackClientId(
      window.__DEPLOYMENT__,
      window.__ENVIRONMENT__,
      nodeEnv,
    ),
    AMPLI_ENV: getAmpliEnvironment(window.__ENVIRONMENT__, nodeEnv),
    SSO_CAPTCHA_SITE_KEY: '6LcbtOAlAAAAABqrWUqkkWNX1NiDhyN0sLYwAh7m',
    RECAPTCHA_V3_SITE_KEY: '6Ld6sOAlAAAAAJSDELnDje0yFHpRGVglkL2OWeoq',
    INTERCOM_CLIENT_ID: getIntercomClientId(window.__ENVIRONMENT__, nodeEnv),
    HUBSPOT_CLIENT_ID: getHubSpotClientId(window.__ENVIRONMENT__, nodeEnv),
    HUBSPOT_REDIRECT_URI: getHubSpotRedirectURI(
      window.__ENVIRONMENT__,
      nodeEnv,
    ),
    HUBSPOT_SCOPE:
      'oauth crm.lists.read crm.lists.write crm.objects.contacts.read crm.import content',
    DATADOG_APP_ID: 'fb8a9eb4-42ae-4f15-8773-2bbffdab6e6d',
    DATADOG_CLIENT_TOKEN: 'pub0b062d82219f14caa0bdbbd249ae08b5',
    MAILCHIMP_CONFIG: getMailchimpConfig(window.__ENVIRONMENT__, nodeEnv),
    QUALTRICS_CONFIG: getQualtricsConfig(window.__ENVIRONMENT__, nodeEnv),
    USERFLOW_CLIENT_TOKEN: getUserflowClientToken(
      window.__ENVIRONMENT__,
      nodeEnv,
    ),
    SALESFORCE_V2_CONFIG: getSalesforceV2Config(
      window.__ENVIRONMENT__,
      nodeEnv,
    ),
    TIKTOKAD_CONFIG: getTiktokAdConfig(window.__ENVIRONMENT__, nodeEnv),
    SNAPCHATAD_CONFIG: getSnapchatAdConfig(window.__ENVIRONMENT__, nodeEnv),
  },
  ADAPTER_V2_JSONATA_TEMPLATE_CONFIG: getJsonataTemplateConfigURL(
    window.__ENVIRONMENT__,
    nodeEnv,
  ),
  ADAPTER_V2_CUSTOMER_TEMPLATE_CONFIGS: getCustomerTemplateConfigURL(
    window.__ENVIRONMENT__,
    nodeEnv,
  ),
  MAILCHIMP_WEBHOOK_URL: getMailchimpWebhookURL(
    window.__ENVIRONMENT__,
    nodeEnv,
  ),
  AMPLITUDE_SDK_ENV_CONFIG: getAmplitudeSdkEnvConfig(
    window.__ENVIRONMENT__,
    nodeEnv,
  ),
  safeRedirectProtocol: window.__DEPLOYMENT__ === 'local' ? 'http:' : 'https:',
  NAT_GATEWAYS: getNATGateways(window.__ENVIRONMENT__),
  PRINCIPAL_AWS: getRootPrincipal(window.__ENVIRONMENT__),
  DEFAULT_AWS_REGION: getDefaultAWSRegion(window.__ENVIRONMENT__),
  VPCE: getVpce(window.__ENVIRONMENT__),
  USER_AVATAR_ORIGIN: getUserAvatarOrigin(window.__ENVIRONMENT__),
  EVENT_SERVER_URL: getEventServerURL(window.__ENVIRONMENT__),
  HTTP_API_URL: getHttpApiUrl(window.__ENVIRONMENT__, nodeEnv),
  HTTP_API_V2_URL: getHttpApiV2Url(window.__ENVIRONMENT__, nodeEnv),
  ENV: window.__ENVIRONMENT__ ?? process.env.NODE_ENV,
  VERSION: process.env.REACT_APP_VERSION,
  HERMES_HOST: getHermesHost(window.__ENVIRONMENT__, nodeEnv),
  STARGATE_HOST_URL: getStargateHost(window.__ENVIRONMENT__, nodeEnv),
  ORBIT_API_BASE_URL: getOrbitApiBaseUrl(window.__ENVIRONMENT__, nodeEnv),
  LIGHTNING_HOST_URL: getLightningHost(window.__ENVIRONMENT__, nodeEnv),
  ADMIN_HOST_URL: getAdminHost(window.__ENVIRONMENT__, nodeEnv),
  THUNDER_API_BASE_URL: getThunderApiBaseUrl(nodeEnv),
  DASH_API_BASE_URL: getDashApiBaseUrl(nodeEnv),
  ONENAV_HOST_URL: getOneNavHost(window.__ENVIRONMENT__, nodeEnv),
  CEREBRO_HOST_URL: getCerebroHost(window.__ENVIRONMENT__, nodeEnv),
  STRIPE_PRICE_ID_MAP: getStripePriceIdMap(window.__ENVIRONMENT__, nodeEnv),
  STRIPE_PUBLISHABLE_KEY: getStripePublishableKey(window.__ENVIRONMENT__),
  SLACK_APP_ID: getSlackAppId(window.__ENVIRONMENT__, nodeEnv),
  DATA_CONNECTIONS_PUBLIC_KEY: getDataConnectionsPublicKey(
    window.__ENVIRONMENT__,
    nodeEnv,
  ),
  USERFLOW_STREAMING_VERSIONING_FLOW_ID: '0c8689c6-b00e-42b7-9b83-adf6227e226d',
  OVERLAY_INJECT_URL: getOverlayInjectScriptHost(
    window.__ENVIRONMENT__,
    nodeEnv,
  ),
};
