import LightningConfig from 'shared/src/config';
import {
  getHubSpotClientId,
  getHubSpotRedirectURI,
  getIntercomClientId,
  getMailchimpConfig,
  getQualtricsConfig,
  getSalesforceV2Config,
  getSnapchatAdConfig,
  getStargateClientSentryDsn,
  getTiktokAdConfig,
  getUserflowClientToken,
  type IntegrationOAuthConfig,
} from 'shared/src/config/_base';
import { getEnv, isStorybook } from 'shared/src/lib/env';
import { isInOneNav } from 'shared/src/onenav/is-in-onenav';

const nodeEnv = getEnv('NODE_ENV');

interface IConfig {
  AMPLI_ENVIRONMENT: 'development' | 'production';
  DEFAULT_AVATAR_URL: string;
  API_URL: string;
  PUSHER_APP_KEY: string;
  DEVELOPMENT_MODE: boolean;
  LOCALHOST_MODE: boolean;
  DASH_HOST: string;
  THUNDER_HOST: string;
  ORBIT_HOST: string;
  ORBIT_URL: string; // Same as orbit host, but includes the /o/ path on local for use with local proxies.
  ANALYTICS_HOST: string;
  STARGATE_HOST: string;
  DATA_HOST: string;
  SENTRY_DSN: string;
  LOGOUT_URL: string;
  DATADOG_APP_ID: string;
  DATADOG_CLIENT_TOKEN: string;
  JIRA_CLIENT_ID: string;
  JIRA_CALLBACK_URI: string;
  API_ENDPOINT: string;
  USERFLOW_CLIENT_TOKEN: string;
  SKYLAB_CLIENT_KEY: string;
  INTERCOM_CLIENT_ID: string;
  HUBSPOT_CLIENT_ID: string;
  HUBSPOT_REDIRECT_URI: string;
  HUBSPOT_SCOPE: string;
  MAILCHIMP_CONFIG: IntegrationOAuthConfig;
  SALESFORCE_V2_CONFIG: IntegrationOAuthConfig;
  STARGATE_IMPORT_TEMPLATE_URL: string; // the new format (After 2022-03-21)
  ITERATIVELY_IMPORT_TEMPLATE_URL: string; // the old format (Before 2022-03-21)
  STARGATE_IMPORT_USER_PROPERTIES_TEMPLATE: string;
  QUALTRICS_CONFIG: IntegrationOAuthConfig;
  TIKTOKAD_CONFIG: IntegrationOAuthConfig;
  SNAPCHATAD_CONFIG: IntegrationOAuthConfig;
}

export enum Environment {
  LOCAL = 'local',
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PROD_SANDBOX = 'prod-sandbox',
  PRODUCTION = 'production',
  PROD_EU = 'prod-eu',
}

const getStargateHost = () => {
  if (isInOneNav()) {
    return window.location.origin || '';
  }

  return getEnv('REACT_APP_PUBLIC_BASE_PATH') || '';
};

const getAnalyticsHost = () => {
  if (isInOneNav()) {
    return window.location.origin || '';
  }

  return getEnv('REACT_APP_ANALYTICS_HOST') || '';
};

const STARGATE_HOST = getStargateHost();
const ANALYTICS_HOST = getAnalyticsHost();

// TODO: redo this so we're not sending dev config to browsers
const local: IConfig = {
  AMPLI_ENVIRONMENT: 'development',
  API_URL: '/o/graphql',
  DEFAULT_AVATAR_URL: 'https://data.staging.amplitude.com/images/user.png',
  PUSHER_APP_KEY: '90d46d2e9a607bc79649',
  DASH_HOST: '',
  THUNDER_HOST: '',
  ORBIT_HOST: '',
  ORBIT_URL: '/o',
  ANALYTICS_HOST,
  STARGATE_HOST,
  DATA_HOST: getEnv('REACT_APP_PUBLIC_BASE_PATH') || '',
  DEVELOPMENT_MODE: true,
  LOCALHOST_MODE: true,
  SENTRY_DSN: getStargateClientSentryDsn('test'),
  LOGOUT_URL: 'http://localhost:3001/logout',
  DATADOG_APP_ID: '6c1c3815-2121-48ea-9331-283bdb686d17',
  DATADOG_CLIENT_TOKEN: 'puba535736d90207aac3b517869f9ab8900',
  JIRA_CLIENT_ID: 'IxiRkddA0MFtj5MBVrOofF66RYHG0B7t',
  JIRA_CALLBACK_URI: `${
    getEnv('REACT_APP_ORBIT_HOST') || ''
  }/integration/jira/authentication`,
  API_ENDPOINT: 'http://localhost:3000/2/httpapi',
  USERFLOW_CLIENT_TOKEN: getUserflowClientToken(
    Environment.DEVELOPMENT,
    nodeEnv,
  ),
  SKYLAB_CLIENT_KEY: 'client-BPVcZwhNO22uW3sYwOCqZdd13JKOZZIB',
  INTERCOM_CLIENT_ID: getIntercomClientId(Environment.DEVELOPMENT),
  HUBSPOT_CLIENT_ID: getHubSpotClientId(Environment.DEVELOPMENT),
  HUBSPOT_REDIRECT_URI: getHubSpotRedirectURI(Environment.DEVELOPMENT),
  HUBSPOT_SCOPE: LightningConfig.keys.HUBSPOT_SCOPE,
  MAILCHIMP_CONFIG: getMailchimpConfig('', Environment.DEVELOPMENT),
  SALESFORCE_V2_CONFIG: getSalesforceV2Config('', Environment.DEVELOPMENT),
  STARGATE_IMPORT_TEMPLATE_URL: `${getEnv(
    'REACT_APP_PUBLIC_BASE_PATH',
  )}/static/import-template.csv`,
  ITERATIVELY_IMPORT_TEMPLATE_URL: `${getEnv(
    'REACT_APP_PUBLIC_BASE_PATH',
  )}/static/iteratively-import-example.csv`,
  STARGATE_IMPORT_USER_PROPERTIES_TEMPLATE: `${getEnv(
    'REACT_APP_PUBLIC_BASE_PATH',
  )}/static/import-user-properties-template.csv`,
  QUALTRICS_CONFIG: getQualtricsConfig('', Environment.DEVELOPMENT),
  TIKTOKAD_CONFIG: getTiktokAdConfig('', Environment.DEVELOPMENT),
  SNAPCHATAD_CONFIG: getSnapchatAdConfig('', Environment.DEVELOPMENT),
};

const storybook: IConfig = {
  AMPLI_ENVIRONMENT: 'development',
  API_URL: '/o/graphql',
  DEFAULT_AVATAR_URL: 'https://data.staging.amplitude.com/images/user.png',
  PUSHER_APP_KEY: '90d46d2e9a607bc79649',
  DASH_HOST: '',
  THUNDER_HOST: '',
  ORBIT_HOST: '',
  ORBIT_URL: '/o',
  ANALYTICS_HOST,
  STARGATE_HOST,
  DATA_HOST: getEnv('REACT_APP_PUBLIC_BASE_PATH') || '',
  DEVELOPMENT_MODE: true,
  LOCALHOST_MODE: true,
  SENTRY_DSN: getStargateClientSentryDsn('test'),
  LOGOUT_URL: 'http://localhost:3001/logout',
  DATADOG_APP_ID: '6c1c3815-2121-48ea-9331-283bdb686d17',
  DATADOG_CLIENT_TOKEN: 'puba535736d90207aac3b517869f9ab8900',
  JIRA_CLIENT_ID: 'IxiRkddA0MFtj5MBVrOofF66RYHG0B7t',
  JIRA_CALLBACK_URI: `${
    getEnv('REACT_APP_ORBIT_HOST') || ''
  }/integration/jira/authentication`,
  API_ENDPOINT: 'http://localhost:3000/2/httpapi',
  USERFLOW_CLIENT_TOKEN: getUserflowClientToken(
    Environment.DEVELOPMENT,
    nodeEnv,
  ),
  SKYLAB_CLIENT_KEY: 'client-BPVcZwhNO22uW3sYwOCqZdd13JKOZZIB',
  INTERCOM_CLIENT_ID: getIntercomClientId(Environment.DEVELOPMENT),
  HUBSPOT_CLIENT_ID: getHubSpotClientId(Environment.DEVELOPMENT),
  HUBSPOT_REDIRECT_URI: getHubSpotRedirectURI(Environment.DEVELOPMENT),
  HUBSPOT_SCOPE: LightningConfig.keys.HUBSPOT_SCOPE,
  MAILCHIMP_CONFIG: getMailchimpConfig('', Environment.DEVELOPMENT),
  SALESFORCE_V2_CONFIG: getSalesforceV2Config('', Environment.DEVELOPMENT),
  STARGATE_IMPORT_TEMPLATE_URL: `${getEnv(
    'REACT_APP_PUBLIC_BASE_PATH',
  )}/static/import-template.csv`,
  ITERATIVELY_IMPORT_TEMPLATE_URL: `${getEnv(
    'REACT_APP_PUBLIC_BASE_PATH',
  )}/static/iteratively-import-example.csv`,
  STARGATE_IMPORT_USER_PROPERTIES_TEMPLATE: `${getEnv(
    'REACT_APP_PUBLIC_BASE_PATH',
  )}/static/import-user-properties-template.csv`,
  QUALTRICS_CONFIG: getQualtricsConfig('', Environment.DEVELOPMENT),
  TIKTOKAD_CONFIG: getTiktokAdConfig('', Environment.DEVELOPMENT),
  SNAPCHATAD_CONFIG: getSnapchatAdConfig('', Environment.DEVELOPMENT),
};

const staging: IConfig = {
  AMPLI_ENVIRONMENT: 'development',
  API_URL: `${getEnv('REACT_APP_ORBIT_HOST') || ''}/graphql`,
  PUSHER_APP_KEY: '90d46d2e9a607bc79649',
  DASH_HOST: getEnv('REACT_APP_DASH_HOST') || '',
  THUNDER_HOST: getEnv('REACT_APP_THUNDER_HOST') || '',
  ORBIT_HOST: getEnv('REACT_APP_ORBIT_HOST') || '',
  ORBIT_URL: getEnv('REACT_APP_ORBIT_HOST') || '',
  ANALYTICS_HOST,
  STARGATE_HOST,
  DATA_HOST: getEnv('REACT_APP_PUBLIC_BASE_PATH') || '',
  DEFAULT_AVATAR_URL: 'https://data.staging.amplitude.com/images/user.png',
  DEVELOPMENT_MODE: false,
  LOCALHOST_MODE: false,
  SENTRY_DSN: getStargateClientSentryDsn(nodeEnv),
  LOGOUT_URL: getEnv('REACT_APP_LOGOUT_URL') || '',
  DATADOG_APP_ID: '6c1c3815-2121-48ea-9331-283bdb686d17',
  DATADOG_CLIENT_TOKEN: 'puba535736d90207aac3b517869f9ab8900',
  JIRA_CLIENT_ID: 'gWQAxfpdYVrfDgjKMB6SKPaWkqkR3xbm',
  JIRA_CALLBACK_URI: `${
    getEnv('REACT_APP_ORBIT_HOST') || ''
  }/integration/jira/authentication`,
  API_ENDPOINT: 'https://api.staging.amplitude.com/2/httpapi',
  USERFLOW_CLIENT_TOKEN: getUserflowClientToken(Environment.STAGING, nodeEnv),
  SKYLAB_CLIENT_KEY: 'client-BPVcZwhNO22uW3sYwOCqZdd13JKOZZIB',
  INTERCOM_CLIENT_ID: getIntercomClientId(Environment.STAGING),
  HUBSPOT_CLIENT_ID: getHubSpotClientId(Environment.STAGING),
  HUBSPOT_REDIRECT_URI: getHubSpotRedirectURI(Environment.STAGING),
  HUBSPOT_SCOPE: LightningConfig.keys.HUBSPOT_SCOPE,
  MAILCHIMP_CONFIG: getMailchimpConfig(Environment.STAGING, nodeEnv),
  SALESFORCE_V2_CONFIG: getSalesforceV2Config(Environment.STAGING, nodeEnv),
  STARGATE_IMPORT_TEMPLATE_URL: `${getEnv(
    'REACT_APP_PUBLIC_BASE_PATH',
  )}/static/import-template.csv`,
  ITERATIVELY_IMPORT_TEMPLATE_URL: `${getEnv(
    'REACT_APP_PUBLIC_BASE_PATH',
  )}/static/iteratively-import-example.csv`,
  STARGATE_IMPORT_USER_PROPERTIES_TEMPLATE: `${getEnv(
    'REACT_APP_PUBLIC_BASE_PATH',
  )}/static/import-user-properties-template.csv`,
  QUALTRICS_CONFIG: getQualtricsConfig(Environment.STAGING, nodeEnv),
  TIKTOKAD_CONFIG: getTiktokAdConfig(Environment.STAGING, nodeEnv),
  SNAPCHATAD_CONFIG: getSnapchatAdConfig(Environment.STAGING, nodeEnv),
};

const prod: IConfig = {
  AMPLI_ENVIRONMENT: 'production',
  API_URL: `${getEnv('REACT_APP_ORBIT_HOST') || ''}/graphql`,
  PUSHER_APP_KEY: '66ab967c462ebf38692d',
  DASH_HOST: getEnv('REACT_APP_DASH_HOST') || '',
  THUNDER_HOST: getEnv('REACT_APP_THUNDER_HOST') || '',
  ORBIT_HOST: getEnv('REACT_APP_ORBIT_HOST') || '',
  ORBIT_URL: getEnv('REACT_APP_ORBIT_HOST') || '',
  ANALYTICS_HOST,
  STARGATE_HOST,
  DATA_HOST: getEnv('REACT_APP_PUBLIC_BASE_PATH') || '',
  DEFAULT_AVATAR_URL: 'https://data.amplitude.com/images/user.png',
  DEVELOPMENT_MODE: false,
  LOCALHOST_MODE: false,
  SENTRY_DSN: getStargateClientSentryDsn(nodeEnv),
  LOGOUT_URL: getEnv('REACT_APP_LOGOUT_URL') || '',
  DATADOG_APP_ID: '6c1c3815-2121-48ea-9331-283bdb686d17',
  DATADOG_CLIENT_TOKEN: 'puba535736d90207aac3b517869f9ab8900',
  JIRA_CLIENT_ID: 'Z7kml76XujbAdb6BekIUYmPMzMrPZqAD',
  JIRA_CALLBACK_URI: `${
    getEnv('REACT_APP_ORBIT_HOST') || ''
  }/integration/jira/authentication`,
  API_ENDPOINT: 'https://api2.amplitude.com/2/httpapi',
  USERFLOW_CLIENT_TOKEN: getUserflowClientToken(
    Environment.PRODUCTION,
    nodeEnv,
  ),
  SKYLAB_CLIENT_KEY: 'client-OfgYm23h0IZFWeHWojIWH3rDLnDIFx4P',
  INTERCOM_CLIENT_ID: getIntercomClientId(Environment.PRODUCTION),
  HUBSPOT_CLIENT_ID: getHubSpotClientId(Environment.PRODUCTION),
  HUBSPOT_REDIRECT_URI: getHubSpotRedirectURI(Environment.PRODUCTION),
  HUBSPOT_SCOPE: LightningConfig.keys.HUBSPOT_SCOPE,
  MAILCHIMP_CONFIG: getMailchimpConfig(Environment.PRODUCTION, nodeEnv),
  SALESFORCE_V2_CONFIG: getSalesforceV2Config(Environment.PRODUCTION, nodeEnv),
  STARGATE_IMPORT_TEMPLATE_URL: `${getEnv(
    'REACT_APP_PUBLIC_BASE_PATH',
  )}/static/import-template.csv`,
  ITERATIVELY_IMPORT_TEMPLATE_URL: `${getEnv(
    'REACT_APP_PUBLIC_BASE_PATH',
  )}/static/iteratively-import-example.csv`,
  STARGATE_IMPORT_USER_PROPERTIES_TEMPLATE: `${getEnv(
    'REACT_APP_PUBLIC_BASE_PATH',
  )}/static/import-user-properties-template.csv`,
  QUALTRICS_CONFIG: getQualtricsConfig(Environment.PRODUCTION, nodeEnv),
  TIKTOKAD_CONFIG: getTiktokAdConfig(Environment.PRODUCTION, nodeEnv),
  SNAPCHATAD_CONFIG: getSnapchatAdConfig(Environment.PRODUCTION, nodeEnv),
};

const prodEU: IConfig = {
  AMPLI_ENVIRONMENT: 'production',
  API_URL: `${getEnv('REACT_APP_ORBIT_HOST') || ''}/graphql`,
  PUSHER_APP_KEY: '66ab967c462ebf38692d',
  DASH_HOST: getEnv('REACT_APP_DASH_HOST') || '',
  ORBIT_URL: getEnv('REACT_APP_ORBIT_HOST') || '',
  THUNDER_HOST: getEnv('REACT_APP_THUNDER_HOST') || '',
  ORBIT_HOST: getEnv('REACT_APP_ORBIT_HOST') || '',
  ANALYTICS_HOST,
  STARGATE_HOST,
  DATA_HOST: getEnv('REACT_APP_PUBLIC_BASE_PATH') || '',
  DEFAULT_AVATAR_URL: 'https://data.eu.amplitude.com/images/user.png',
  DEVELOPMENT_MODE: false,
  LOCALHOST_MODE: false,
  SENTRY_DSN: getStargateClientSentryDsn(nodeEnv),
  LOGOUT_URL: getEnv('REACT_APP_LOGOUT_URL') || '',
  DATADOG_APP_ID: '6c1c3815-2121-48ea-9331-283bdb686d17',
  DATADOG_CLIENT_TOKEN: 'puba535736d90207aac3b517869f9ab8900',
  JIRA_CLIENT_ID: 'UUL1uwCKUUEHwdZUVU6k1dyTP0bjAU2x',
  JIRA_CALLBACK_URI: `${
    getEnv('REACT_APP_ORBIT_HOST') || ''
  }/integration/jira/authentication`,
  API_ENDPOINT: 'https://api.eu.amplitude.com/2/httpapi',
  USERFLOW_CLIENT_TOKEN: getUserflowClientToken(Environment.PROD_EU, nodeEnv),
  SKYLAB_CLIENT_KEY: 'client-OfgYm23h0IZFWeHWojIWH3rDLnDIFx4P',
  INTERCOM_CLIENT_ID: getIntercomClientId(Environment.PROD_EU),
  HUBSPOT_CLIENT_ID: getHubSpotClientId(Environment.PROD_EU),
  HUBSPOT_REDIRECT_URI: getHubSpotRedirectURI(Environment.PROD_EU),
  HUBSPOT_SCOPE: LightningConfig.keys.HUBSPOT_SCOPE,
  MAILCHIMP_CONFIG: getMailchimpConfig(Environment.PROD_EU, nodeEnv),
  SALESFORCE_V2_CONFIG: getSalesforceV2Config(Environment.PROD_EU, nodeEnv),
  STARGATE_IMPORT_TEMPLATE_URL: `${getEnv(
    'REACT_APP_PUBLIC_BASE_PATH',
  )}/static/import-template.csv`,
  ITERATIVELY_IMPORT_TEMPLATE_URL: `${getEnv(
    'REACT_APP_PUBLIC_BASE_PATH',
  )}/static/iteratively-import-example.csv`,
  STARGATE_IMPORT_USER_PROPERTIES_TEMPLATE: `${getEnv(
    'REACT_APP_PUBLIC_BASE_PATH',
  )}/static/import-user-properties-template.csv`,
  QUALTRICS_CONFIG: getQualtricsConfig(Environment.PROD_EU, nodeEnv),
  TIKTOKAD_CONFIG: getTiktokAdConfig(Environment.PROD_EU, nodeEnv),
  SNAPCHATAD_CONFIG: getSnapchatAdConfig(Environment.PROD_EU, nodeEnv),
};

const getConfig = () => {
  if (isStorybook()) {
    return storybook;
  }

  if (getEnv('REACT_APP_ENV') === Environment.PRODUCTION) {
    return prod;
  }

  if (getEnv('REACT_APP_ENV') === Environment.PROD_EU) {
    return prodEU;
  }

  if (getEnv('REACT_APP_ENV') === Environment.STAGING) {
    return staging;
  }

  return local;
};

export default getConfig();
