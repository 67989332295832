import type { LocationDescriptor, LocationDescriptorObject } from 'history';

import { Product, Product as TempProduct } from 'cerulean/dist/lib/product';

import { isInOneNav } from 'shared/src/onenav/is-in-onenav';
import type {
  AmplitudeHubId,
  ProductAnalyticsPage,
} from 'shared/src/types/hubs';

export { TempProduct };

export const ONE_NAV_SUBDOMAIN_RE =
  /^\/(?:analytics|audiences|data|experiment|guides-surveys)\/([^/]+)/;
const DEV_DOCS_SUBDOMAIN_RE = /^\/amp-dev-docs\/(.+)/;
const ORG_URL_SUBDOMAIN_RE = /^\/([^/]+)/;

export const getOrgIdFromLocation = (): string | null => {
  let regex = /^\/org\/(\d+)/;

  if (isInOneNav()) {
    regex =
      /^\/(?:analytics|audiences|data|experiment|guides-surveys)\/org\/(\d+)/;
  }

  const orgMatch = window.location.pathname.match(regex);

  return orgMatch?.[1] || null;
};

export const getHubIdFromPathname = (
  pathname: string,
): AmplitudeHubId | null => {
  const match = pathname.match(/\/space\/([^/]+)/);
  return (match?.[1] as AmplitudeHubId) || null;
};

export const getHubTabIdFromPathname = (
  pathname: string,
): ProductAnalyticsPage | null => {
  const match = pathname.match(/\/space\/[^/]+\/([^/?]+)/);
  return (match?.[1] as ProductAnalyticsPage) || null;
};

const reservedOrgUrls = [
  'org',
  'share',
  'subscriptions',
  'iframe',
  'external-embed',
];

export const getOrgUrlFromLocation = (): string | null => {
  const regex = isInOneNav() ? ONE_NAV_SUBDOMAIN_RE : ORG_URL_SUBDOMAIN_RE;

  const orgUrlMatch = window.location.pathname.match(regex);

  const orgUrl = orgUrlMatch?.[1].toLowerCase() || null;

  if (orgUrl && reservedOrgUrls.includes(orgUrl)) {
    return null;
  }

  return orgUrl;
};

export const getPathFromLocation = (): string | null => {
  const regex = isInOneNav() ? ONE_NAV_SUBDOMAIN_RE : DEV_DOCS_SUBDOMAIN_RE;

  const pathMatch = window.location.pathname.match(regex);

  return pathMatch?.[1] ?? null;
};

export const isIframeLocation = (): boolean => {
  if (isInOneNav()) {
    return /^(?:\/analytics)?\/iframe\//.test(window.location.pathname);
  }

  return /^\/iframe\//.test(window.location.pathname);
};

export const isShareLocation = (): boolean => {
  if (isInOneNav()) {
    return /^(?:\/analytics)?\/share\/\w+/.test(window.location.pathname);
  }

  return /^\/share\/\w+/.test(window.location.pathname);
};

export const isUnsubscribeLocation = (): boolean => {
  if (isInOneNav()) {
    return /^(?:\/analytics)?\/subscriptions\/unsubscribe\/\w+/.test(
      window.location.pathname,
    );
  }

  return /^\/subscriptions\/unsubscribe\/\w+/.test(window.location.pathname);
};

export const isExternalEmbedLocation = (): boolean => {
  if (isInOneNav()) {
    return /^(?:\/analytics)?\/external-embed\/?/.test(
      window.location.pathname,
    );
  }

  return /^\/external-embed\/?/.test(window.location.pathname);
};

/**
 * Gets the product name from pathname
 * Example: https://apps.amplitude.com/analytics => 'analytics'
 */
export const getCurrentProductFromUrl = (): Product => {
  const currentProductMatch = window.location.pathname.match(
    /^\/(analytics|audiences|data|experiment|guides-surveys)/,
  );

  return currentProductMatch?.[1]
    ? (currentProductMatch[1] as Product)
    : Product.ANALYTICS;
};

export const getCurrentPathname = (pathname: string): string => {
  return isInOneNav() && pathname.match(ONE_NAV_SUBDOMAIN_RE)
    ? pathname.replace(
        /^\/(analytics|audiences|data|experiment|guides-surveys)/,
        '',
      )
    : pathname;
};

/**
 * Gets the product name from window.__CURRENTAPP__
 */
export const getCurrentProduct = (): Product => {
  return window.__CURRENTAPP__ as Product;
};

/**
 * @todo Mae Capozzi - Cerulean sideloading
 * This will be removed in favor of getCurrentProduct
 * the work to stop sideloading cerulean is complete.
 **/
export const tempGetCurrentProduct = (): TempProduct => {
  return window.__CURRENTAPP__ as TempProduct;
};

export const getPathStringFromLocation = (
  location: LocationDescriptor,
): string => {
  if (typeof location === 'string') {
    return location;
  }

  if ('pathname' in location && location.pathname) {
    return location.pathname;
  }

  return '';
};

export const forwardAllSearchParamsToLocation = <S>(
  location: LocationDescriptorObject<S>,
  searchParams: URLSearchParams,
): LocationDescriptorObject<S> => {
  const existingParams = new URLSearchParams(location.search);

  searchParams.forEach((value, key) => {
    existingParams.set(key, value);
  });

  return {
    ...location,
    search: existingParams.toString(),
  };
};
