import type { LocationDescriptor } from 'history';

import { Environment, Product, ProductInfo } from 'cerulean/dist/lib/product';

import { ONE_NAV_SUBDOMAIN_RE } from 'shared/src/lib/location';
import type { LocationDescriptorObjectWithPathname } from 'shared/src/types/history';

import { isInOneNav, isInStorybook } from './is-in-onenav';

const environment = (window?.__ENVIRONMENT__ as Environment) ?? 'local';
export const currentApp = window?.__CURRENTAPP__;

export const isInAnalytics = currentApp === Product.ANALYTICS;
export const isInAudiences = currentApp === Product.AUDIENCES;
export const isInData = currentApp === Product.DATA;
export const isInExperiment = currentApp === Product.EXPERIMENT;
export const isInExplorer = currentApp === Product.EXPLORER;
export const isInGuidesSurveys = currentApp === Product.GUIDES_SURVEYS;

export const getIsInData = (): boolean =>
  window?.__CURRENTAPP__ === Product.DATA;
export const getIsInAnalytics = (): boolean =>
  window?.__CURRENTAPP__ === Product.ANALYTICS;
export const getIsInAudiences = (): boolean =>
  window?.__CURRENTAPP__ === Product.AUDIENCES;
export const getIsInExperiment = (): boolean =>
  window?.__CURRENTAPP__ === Product.EXPERIMENT;
export const getIsInGuidesSurveys = (): boolean =>
  window?.__CURRENTAPP__ === Product.GUIDES_SURVEYS;

export const getCurrentProduct = (): Product => {
  if (isInAudiences) {
    return Product.AUDIENCES;
  }
  if (isInData) {
    return Product.DATA;
  }
  if (isInExperiment) {
    return Product.EXPERIMENT;
  }

  if (isInGuidesSurveys) {
    return Product.GUIDES_SURVEYS;
  }

  return Product.ANALYTICS;
};

const Products = {
  [Product.ANALYTICS]: {
    fallbackHost: process.env.REACT_APP_ANALYTICS_HOST ?? '',
    isIn: isInAnalytics,
  },
  [Product.AUDIENCES]: {
    fallbackHost: process.env.REACT_APP_CEREBRO_HOST ?? '',
    isIn: isInAudiences,
  },
  [Product.DATA]: {
    fallbackHost: process.env.REACT_APP_STARGATE_HOST ?? '',
    isIn: isInData,
  },
  [Product.EXPERIMENT]: {
    fallbackHost: process.env.REACT_APP_SKYLAB_HOST ?? '',
    isIn: isInExperiment,
  },
  [Product.EXPLORER]: {
    fallbackHost: process.env.REACT_APP_EXPLORER_HOST ?? '',
    isIn: isInExplorer,
  },
  [Product.GUIDES_SURVEYS]: {
    fallbackHost: process.env.REACT_APP_ASSISTANCE_HOST ?? '',
    isIn: isInGuidesSurveys,
  },
};

export const addLeadingSlash = (
  path: string | undefined,
): string | undefined => {
  if (path?.length && path.charAt(0) !== '/') {
    return `/${path}`;
  }

  return path;
};

export const removeTrailingSlash = (
  path: string | undefined,
): string | undefined => {
  if (path?.length && path.charAt(path.length - 1) === '/') {
    return path.slice(0, -1);
  }

  return path;
};

const getProductPrefix = (product: Product) => {
  if (isInStorybook()) {
    return '';
  }

  if (!isInOneNav()) {
    if (Products[product].isIn) {
      return '';
    }

    const envUrl = ProductInfo[product].urls[environment];

    if (envUrl) {
      return envUrl;
    }

    return (
      Products[product].fallbackHost ||
      ProductInfo[product].urls[Environment.PRODUCTION]
    );
  }

  // in onenav, product routes will be prefixed with their product
  // ex. /analytics/{orgUrl}/cohorts
  return `/${product}`;
};

const createRewriteToProductLocation =
  (product: Product) =>
  (location: LocationDescriptor): LocationDescriptorObjectWithPathname => {
    const productPrefix = getProductPrefix(product);

    if (typeof location === 'string') {
      const locationWithSlash = addLeadingSlash(location) || '';

      const alreadyHasPrefix = locationWithSlash.match(ONE_NAV_SUBDOMAIN_RE);

      // if the path already starts with the product prefix, don't prepend it
      if (alreadyHasPrefix) {
        return {
          pathname: locationWithSlash,
        };
      }

      return {
        pathname: productPrefix + locationWithSlash,
      };
    }

    const pathWithSlash = addLeadingSlash(location.pathname) || '';
    const alreadyHasPrefix = pathWithSlash.match(ONE_NAV_SUBDOMAIN_RE);

    if (alreadyHasPrefix) {
      return {
        pathname: pathWithSlash,
      };
    }

    return {
      ...location,
      pathname: productPrefix + pathWithSlash,
    };
  };

const createRewriteToProductPath =
  (product: Product) =>
  (location: string): string => {
    const productPrefix = getProductPrefix(product);

    const locationWithSlash = addLeadingSlash(location) || '';

    const alreadyHasPrefix = locationWithSlash.match(ONE_NAV_SUBDOMAIN_RE);

    // if the path already starts with the product prefix, don't prepend it
    if (alreadyHasPrefix) {
      return locationWithSlash;
    }

    return productPrefix + locationWithSlash;
  };

export const rewriteToAnalytics = createRewriteToProductLocation(
  Product.ANALYTICS,
);

export const rewriteToAnalyticsPath = createRewriteToProductPath(
  Product.ANALYTICS,
);

export const rewriteToAudiences = createRewriteToProductLocation(
  Product.AUDIENCES,
);

export const rewriteToAudiencesPath = createRewriteToProductPath(
  Product.AUDIENCES,
);

export const rewriteToData = createRewriteToProductLocation(Product.DATA);

export const rewriteToDataPath = createRewriteToProductPath(Product.DATA);

export const rewriteToExperiment = createRewriteToProductLocation(
  Product.EXPERIMENT,
);

export const rewriteToExperimentPath = createRewriteToProductPath(
  Product.EXPERIMENT,
);

export const rewriteToExplorer = createRewriteToProductLocation(
  Product.EXPLORER,
);

export const rewriteToExplorerPath = createRewriteToProductPath(
  Product.EXPLORER,
);

export const rewriteToGuidesSurveys = createRewriteToProductLocation(
  Product.GUIDES_SURVEYS,
);

export const rewriteToGuidesSurveysPath = createRewriteToProductPath(
  Product.GUIDES_SURVEYS,
);

export const coerceLocationToPathname = (
  maybeLocation: LocationDescriptor,
): string => {
  return typeof maybeLocation === 'object' && maybeLocation.pathname
    ? `${maybeLocation.pathname}${
        maybeLocation.search ? `?${maybeLocation.search}` : ''
      }`
    : (maybeLocation as string);
};
