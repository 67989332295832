// Importing to resolve ci missing type
import type { EnvironmentVariable, Window } from 'shared/src/types/environment';

export const IS_DEV = process.env.NODE_ENV === 'development';
export const isDev = (): boolean => {
  return IS_DEV;
};

export const isStorybook = (): boolean => {
  return !!window.__STORYBOOK__;
};

export const isStaging = (): boolean => {
  return (
    window.__DEPLOYMENT__ === 'stag' || window.__ENVIRONMENT__ === 'staging'
  );
};

export const isProd = (): boolean => {
  return window.__DEPLOYMENT__ === 'prod';
};

export const isProdUs = (): boolean => {
  return window.__ENVIRONMENT__ === 'production';
};

export const isProdEu = (): boolean => {
  return window.__ENVIRONMENT__ === 'prod-eu';
};

export const isProdSandbox = (): boolean => {
  return window.__ENVIRONMENT__ === 'prod-sandbox';
};

export const isEphemeralEnv = (): boolean => {
  // TODO: remove second condition once window.__NAMESPACE__ is set for stargate ephemeral envs.
  return (
    !!window.__NAMESPACE__ ||
    !!window.location.origin.match(
      '-stargate-client.prod-sandbox.amplitude.com',
    ) ||
    !!window.location.origin.match('-audiences.prod-sandbox.amplitude.com')
  );
};

export const getAllFlagValues = () => ({
  ...window.__AMP_FEATURE_FLAGS__,
});

export const getFlagValue = (flag: string): string | undefined => {
  return window.__AMP_FEATURE_FLAGS__?.[flag]?.value as string | undefined;
};

export const isFlagEnabled = (flag: string): boolean => {
  return getFlagValue(flag) === 'on';
};

export const getCurrentEnvironment = (): string | undefined => {
  // TODO: AMP-75106 Fix this the next time the file is edited.
  // eslint-disable-next-line no-use-before-define
  return getEnv('REACT_APP_ENV');
};

export const processEnv: Record<EnvironmentVariable, string | undefined> = {
  NODE_ENV: process.env.NODE_ENV,
  REACT_APP_ANALYTICS_HOST: process.env.REACT_APP_ANALYTICS_HOST,
  REACT_APP_DASH_HOST: process.env.REACT_APP_DASH_HOST,
  REACT_APP_ENV: process.env.REACT_APP_ENV,
  REACT_APP_LOGOUT_URL: process.env.REACT_APP_LOGOUT_URL,
  REACT_APP_ORBIT_HOST: process.env.REACT_APP_ORBIT_HOST,
  REACT_APP_PUBLIC_BASE_PATH: process.env.REACT_APP_PUBLIC_BASE_PATH,
  REACT_APP_SKYLAB_BACKEND_HOST: process.env.REACT_APP_SKYLAB_BACKEND_HOST,
  REACT_APP_THUNDER_HOST: process.env.REACT_APP_THUNDER_HOST,
  REACT_APP_VERSION: process.env.REACT_APP_VERSION,
  REACT_APP_SESSION_REPLAY_HOST: process.env.REACT_APP_SESSION_REPLAY_HOST,
};

export const getEnv = (key: EnvironmentVariable): string =>
  (window as unknown as Window).env?.[key] || processEnv[key] || '';

export const setEnv = (key: EnvironmentVariable, value: string): void => {
  if ((window as unknown as Window).env) {
    (window as unknown as Window).env[key] = value;
  }
  processEnv[key] = value;
};
